import { Flex, useToast } from '@chakra-ui/react'
import useQueryParams from '@hooks/use-query-params'
import useUTMTracking from '@hooks/use-utm-tracking'
import { useAppDispatch } from '@src/features'
import { loginAction, resetSavedSignupState } from '@src/features/auth/auth.actions'
import { selectorAuth, setIsRegistered, setSavedSignupState, setSavedUserCode } from '@src/features/auth/auth.slice'
import Api from '@src/services/api/api.service'
import { getFirebaseAuth, sendEmailVerificationOptions } from '@src/services/firebase.service'
import { createUserWithEmailAndPassword, User as FirebaseUser, sendEmailVerification } from 'firebase/auth'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom'
import { User } from 'typings/database.d'
import AccountDetailsFields from './account-details-fields'
import CodeTermsFields from './code-terms-fields'
import SignupScreenFormFooter from './signup-screen-form-footer'
import UserDetailsFields from './user-details-fields'

function SignupScreenForm(props: { step: number; queryParams: URLSearchParams }) {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const utm = useUTMTracking()
  const authState = useSelector(selectorAuth)
  const { user, firebaseUser, savedSignupState, savedUserCode } = useSelector(selectorAuth)

  const userCode = savedUserCode ? savedUserCode : queryParams.get('userCode') ?? ''
  const isSSO = queryParams.get('isSSO') ?? false
  const email: string = savedSignupState?.email ? savedSignupState.email : queryParams.get('email') ?? ''
  const isProviderApple = firebaseUser?.providerData.some(({ providerId }) => providerId === 'apple.com')
  const appleSignInCache = JSON.parse(localStorage.getItem('apple-signin-cache') || '{}')
  const firstNameApple: string = appleSignInCache.firstName
  const lastNameApple: string = appleSignInCache.lastName

  const firstName: string | undefined = firebaseUser?.displayName?.split(' ')?.at(0)
  const lastName: string[] | undefined = firebaseUser?.displayName?.split(' ')?.slice(1)

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      userCode,
      email,
      firebaseUid: savedSignupState?.firebaseUid ? savedSignupState.firebaseUid : '',
      password: savedSignupState?.password ? savedSignupState.password : '',
      confirmPassword: savedSignupState?.confirmPassword ? savedSignupState.confirmPassword : '',
      hasTermsAndConditionAccepted: savedSignupState?.hasTermsAndConditionAccepted ? savedSignupState.hasTermsAndConditionAccepted : false,
      hasPrivacyPolicyAccepted: savedSignupState?.hasPrivacyPolicyAccepted ? savedSignupState.hasPrivacyPolicyAccepted : false,
      hasMarketingCommunicationsAccepted: savedSignupState?.hasMarketingCommunicationsAccepted
        ? savedSignupState.hasMarketingCommunicationsAccepted
        : false,
      birthdate: savedSignupState?.birthdate ? savedSignupState.birthdate : moment().subtract(18, 'years').format('YYYY-MM-DD'),
      firstName:
        ((isSSO ? firstName ?? '' : '') as string) ||
        (isProviderApple ? firstNameApple : false) ||
        (savedSignupState?.firstName ? savedSignupState.firstName : ''),
      lastName:
        ((isSSO ? lastName?.join(' ') ?? '' : '') as string) ||
        (isProviderApple ? lastNameApple : false) ||
        (savedSignupState?.lastName ? savedSignupState.lastName : ''),
      gender: ((user?.gender ?? '') as string) || (savedSignupState?.gender ? savedSignupState.gender : ''),
    },
  })

  setValue('email', email)

  const handleOnSubmit = (data: any) => {
    handleSubmit(handleNextStep, showErrors)(data)
  }

  const onSubmit = async (data: User) => {
    setIsLoading(true)

    let user: FirebaseUser | undefined
    const auth = getFirebaseAuth()
    if (!auth.currentUser) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)
        user = userCredential.user
      } catch (error: any) {
        if (error.code === 'auth/email-already-in-use') {
          setIsLoading(false)
          return toast({
            title: 'Email già registrata',
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'top',
          })
        } else {
          setIsLoading(false)
          return toast({
            title: "Errore di sistema, contattare l'assistenza",
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'top',
          })
        }
      }
    }

    if (!user) {
      setIsLoading(false)
      return toast({
        title: "Errore di sistema, contattare l'assistenza",
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }

    const firebaseUid = user.uid ?? auth.currentUser?.uid
    const email = user.email ?? auth.currentUser?.email

    sendEmailVerification(user, sendEmailVerificationOptions)

    try {
      const login = await Api.signUpV2({
        email,
        firebaseUid,
        hasTermsAndConditionAccepted: data.hasTermsAndConditionAccepted,
        hasPrivacyPolicyAccepted: data.hasPrivacyPolicyAccepted,
        hasMarketingCommunicationsAccepted: data.hasMarketingCommunicationsAccepted,
        userCode: data.userCode || undefined,
        firstName: data.firstName,
        lastName: data.lastName,
        birthdate: new Date(data.birthdate),
        gender: data.gender,
        ...utm,
      })

      if (login && login.status === 200) {
        dispatch<any>(resetSavedSignupState())
        dispatch<any>(setIsRegistered(true))
        await dispatch<any>(loginAction())
        if (auth.currentUser?.displayName) {
          const [firstName, ...lastName] = auth.currentUser.displayName.split(' ')
          await Api.editUser({
            firstName: firstName,
            lastName: lastName.join(' '),
          })
        }
      }
      dispatch<any>(setSavedUserCode(null))
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    }
  }

  const handleNextStep = async data => {
    if (props.step === 1) {
      dispatch(
        setSavedSignupState({
          email: data.email,
          firebaseUid: savedSignupState?.firebaseUid ? savedSignupState.firebaseUid : '',
          password: data.password,
          confirmPassword: data.confirmPassword,
          hasTermsAndConditionAccepted: false,
          hasPrivacyPolicyAccepted: false,
          hasMarketingCommunicationsAccepted: false,
          birthdate: savedSignupState?.birthdate ? savedSignupState.birthdate : '',
          firstName: savedSignupState?.firstName ? savedSignupState.firstName : '',
          lastName: savedSignupState?.lastName ? savedSignupState.lastName : '',
          gender: savedSignupState?.gender ? savedSignupState.gender : '',
        }),
      )
    } else if (props.step === 2) {
      dispatch(
        setSavedSignupState({
          email: data.email,
          firebaseUid: '',
          password: data.password,
          confirmPassword: data.confirmPassword,
          hasTermsAndConditionAccepted: false,
          hasPrivacyPolicyAccepted: false,
          hasMarketingCommunicationsAccepted: false,
          birthdate: data.birthdate,
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender,
        }),
      )
    } else if (props.step === 3) {
      return onSubmit(data)
    }
    let entries: URLSearchParamsInit = {}
    props.queryParams.forEach((value: string, key: string) => {
      entries[key] = value
    })
    entries['step'] = String(props.step + 1)
    navigate({
      search: `?${createSearchParams(entries)}`,
    })
  }

  const password = watch('password')
  const gender = watch('gender')
  const birthdate = watch('birthdate')
  const hasTermsAndConditionAccepted = watch('hasTermsAndConditionAccepted')
  const hasPrivacyPolicyAccepted = watch('hasPrivacyPolicyAccepted')
  const hasMarketingCommunicationsAccepted = watch('hasMarketingCommunicationsAccepted')

  useEffect(() => {
    const auth = getFirebaseAuth()
    if (auth.currentUser && !authState.isRegistered && props.step === 1) {
      navigate({
        search: `?${createSearchParams({
          email: auth.currentUser.email + '',
          isSSO: 'true',
        })}`,
      })
    } else if (authState.isRegistered && authState.user) {
      navigate('/')
    }
  }, [authState, navigate, props.step])

  useEffect(() => {
    if (user) {
      const currentValues = getValues()
      if (!currentValues.firstName || !currentValues.lastName) {
        setValue('firstName', user.firstName)
        setValue('lastName', user.lastName)
      }
      if (!currentValues.gender) {
        setValue('gender', user.gender)
      }
    }
  }, [user, getValues, setValue])

  const showErrors = errors => {
    Object.values(errors).forEach((value: any) => {
      return toast({
        title: value?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    })
  }

  return (
    <form style={{ display: 'contents' }} onSubmit={handleOnSubmit}>
      <Flex height="100%" flexDir="column">
        {props.step === 1 ? (
          <AccountDetailsFields errors={errors} register={register} password={password} queryParams={queryParams} />
        ) : props.step === 2 ? (
          <UserDetailsFields errors={errors} register={register} gender={gender} setValue={setValue} birthdate={birthdate} />
        ) : (
          <CodeTermsFields
            userCode={userCode}
            register={register}
            errors={errors}
            hasTermsAndConditionAccepted={hasTermsAndConditionAccepted}
            hasPrivacyPolicyAccepted={hasPrivacyPolicyAccepted}
            hasMarketingCommunicationsAccepted={hasMarketingCommunicationsAccepted}
          />
        )}
        <SignupScreenFormFooter
          step={props.step}
          isLoading={isLoading}
          navigate={navigate}
          createSearchParams={createSearchParams}
          queryParams={props.queryParams}
        />
      </Flex>
    </form>
  )
}

export default SignupScreenForm

const SYSTEM_AUTHOR_NAME = 'Sbam!'
const SYSTEM_AUTHOR_NAME_AI = 'Sbam! AI'

enum PAGE_ENUM {
    PAST = 'pastEvents',
    FUTURE = 'futureEvents',
}

enum WarningTypeModel {
    Admonition = 'Admonition',
    Ban = 'Ban',
}

export { SYSTEM_AUTHOR_NAME, SYSTEM_AUTHOR_NAME_AI, PAGE_ENUM, WarningTypeModel }

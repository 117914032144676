import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { useFormbitContext } from '@radicalbit/formbit'
import useGetEventById from '@src/screens/chat/hooks/use-get-event-by-id'
import { Attendance } from '@src/typings/database'
import { refreshUser } from 'features/auth/auth.actions'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import useHandleConfirmDeleteAttendance from './handle-confirm-delete-attendance'
import { EventScreenSchema } from '../../event-screen-schema'

const AttendanceDeletionDialog = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()

  const isDeleteAttendanceOpen = form?.__metadata?.isDeleteAttendanceOpen ?? false
  const cancelDeleteAttendanceRef = form?.__metadata?.cancelDeleteAttendanceRef

  const onDeleteAttendanceClose = () => write('__metadata.isDeleteAttendanceOpen', false)

  return (
    <AlertDialog isCentered isOpen={isDeleteAttendanceOpen} leastDestructiveRef={cancelDeleteAttendanceRef} onClose={onDeleteAttendanceClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <AttendanceDeletionDialogHeader />

          <AttendanceDeletionDialogBody />

          <AttendanceDeletionDialogFooter />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const AttendanceDeletionDialogFooter = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()
  const cancelDeleteAttendanceRef = form?.__metadata?.cancelDeleteAttendanceRef

  const { user } = useSelector(selectorAuth)

  const { handleConfirmDeleteAttendance } = useHandleConfirmDeleteAttendance()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const onDeleteAttendanceClose = () => write('__metadata.isDeleteAttendanceOpen', false)

  const confirmDeleteAttendance = async () => {
    if (attendance) {
      await handleConfirmDeleteAttendance()
      refreshUser()
    }
  }

  return (
    <AlertDialogFooter>
      <Button
        variant="ghost"
        width="20%"
        fontSize="18px"
        fontWeight="600"
        fontFamily="Poppins"
        textAlign="center"
        pr="50px"
        pl="50px"
        bg="white"
        color="black"
        ref={cancelDeleteAttendanceRef}
        onClick={onDeleteAttendanceClose}
        mr="2">
        Indietro
      </Button>

      <Button fontSize="18px" width="80%" fontWeight="600" fontFamily="Poppins" textAlign="center" onClick={confirmDeleteAttendance}>
        Annulla partecipazione
      </Button>
    </AlertDialogFooter>
  )
}

const AttendanceDeletionDialogBody = () => {
  return <AlertDialogBody>Non verranno rimborsati gli sbam coin spesi. Sei sicuro di voler annullare la tua partecipazione?</AlertDialogBody>
}

const AttendanceDeletionDialogHeader = () => {
  return (
    <>
      <AlertDialogHeader fontWeight="bold">Annulla partecipazione</AlertDialogHeader>
      <ModalCloseButton size="lg" />
    </>
  )
}

export default AttendanceDeletionDialog

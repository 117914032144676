import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { Attendance } from '@src/typings/database'
import { selectorAuth } from 'features/auth/auth.slice'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { calculateDistance } from 'utils/calculate-distance.util'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { ReactComponent as PositionIcon } from './../../assets/position.svg'

const EventLocation = () => {
  const { user, position } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const address = data?.location?.details?.address
  const realPosition = data?.location?.realPosition
  const fakePosition = data?.location?.fakePosition
  const provinceName = data?.location?.province?.name

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const goToMap = () => {
    window.open(
      isOrganizer || attendance
        ? `https://www.google.com/maps/search/
          ${address}
          /@
          ${realPosition!.coordinates![0]}
          ,
          ${realPosition!.coordinates![1]}
          z/`
        : `https://www.google.com/maps/search/?api=1&query=${fakePosition!.coordinates![1]}%2C${fakePosition!.coordinates![0]}`,
      '_blank',
    )
  }

  return (
    <Flex direction="row" alignItems="center" justifyContent="space-between">
      <Flex direction="row" alignItems="center">
        <Icon mr="2" as={PositionIcon} boxSize="20px" color="#1A5436" />

        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" lineHeight="22.5px">
          {(isOrganizer || attendance) && `in ${address}`}
          {!isOrganizer &&
            !attendance &&
            position &&
            fakePosition &&
            `a ${calculateDistance(position, {
              lon: fakePosition!.coordinates![0],
              lat: fakePosition!.coordinates![1],
            })}km da te (${provinceName})`}
          {!isOrganizer && !attendance && (!position || !fakePosition) && `a ${provinceName}`}
        </Text>
      </Flex>

      <Button
        onClick={goToMap}
        variant="outline"
        height="30px"
        fontFamily="Poppins"
        fontWeight="500"
        fontSize="15px"
        lineHeight="18px"
        textAlign="center"
        width="62px"
        pl="9"
        pr="9"
        position="relative">
        Mappa
      </Button>
    </Flex>
  )
}

export default EventLocation

import { QuestionIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { AppContext } from 'app.context'
import { useAppDispatch } from 'features'

import { logoutAction } from 'features/auth/auth.actions'
import React, { useContext } from 'react'
import { BsTiktok } from 'react-icons/bs'
import { CiUser } from 'react-icons/ci'
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa'
import { IoMdStarOutline } from 'react-icons/io'
import { IoReturnDownBackSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { NewProfileSchema } from './schema'

function ProfileMenuModal() {
  const { form, write } = useFormbitContext<NewProfileSchema>()
  const isProfileMenuOpen = form?.__metadata?.isProfileMenuOpen ?? false

  const handleCloseProfileMenu = () => {
    write('__metadata.isProfileMenuOpen', false)
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { resetUnreadMessages } = useContext(AppContext)

  return (
    <Modal onClose={handleCloseProfileMenu} size="full" isOpen={isProfileMenuOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="22px" fontWeight="700">
            Impostazioni
          </Text>
        </ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody position="relative">
          <Flex direction="column" mt="50px">
            <HStack onClick={() => navigate('/settings')} pl="1" pt="10px" pb="10px" mb="10px" _hover={{ backgroundColor: '#edf2f7' }}>
              <Icon as={CiUser} />
              <Text fontSize="19px">Gestisci Account</Text>
            </HStack>
            <HStack onClick={() => navigate('/intro')} pl="1" pt="10px" pb="10px" mb="10px" _hover={{ backgroundColor: '#edf2f7' }}>
              <QuestionIcon />
              <Text fontSize="19px">Mostra Tutorial</Text>
            </HStack>
            <HStack
              onClick={() => window.open('https://sbam.social/ambassador', '_blank')}
              pl="1"
              pt="10px"
              pb="10px"
              mb="10px"
              _hover={{ backgroundColor: '#edf2f7' }}>
              <Icon as={IoMdStarOutline} />
              <Text fontSize="19px">Diventa Ambassador</Text>
            </HStack>
            <HStack
              onClick={() => window.open('https://sbam.social/supporto', '_blank')}
              pl="1"
              pt="10px"
              pb="10px"
              mb="10px"
              _hover={{ backgroundColor: '#edf2f7' }}>
              <Icon as={WarningIcon} />
              <Text fontSize="19px">Contatta Assistenza</Text>
            </HStack>
          </Flex>
          <VStack position="fixed" bottom="45px" width="100%" spacing="20px">
            <Flex width="100%" direction="row" position="relative" right="20px">
              <Spacer />
              <Spacer />
              <Spacer />
              <Icon
                boxSize="6"
                as={FaInstagram}
                onClick={() => window.open('https://www.instagram.com/sbam.social', '_blank')}
                _hover={{ color: 'brand.500' }}
              />
              <Spacer />
              <Icon
                boxSize="6"
                as={BsTiktok}
                onClick={() => window.open('https://www.tiktok.com/@sbam.social', '_blank')}
                _hover={{ color: 'brand.500' }}
              />
              <Spacer />
              <Icon
                boxSize="6"
                as={FaTelegramPlane}
                onClick={() => window.open('https://t.me/+tlnMwDn85qllMjM0', '_blank')}
                _hover={{ color: 'brand.500' }}
              />
              <Spacer />
              <Spacer />
              <Spacer />
            </Flex>
            <Divider />
            <Flex width="100%" direction="row" position="relative" right="20px">
              <Spacer />
              <Spacer />
              <Spacer />
              <Box
                onClick={() => {
                  dispatch<any>(logoutAction())
                  resetUnreadMessages?.()
                }}>
                <HStack spacing="10px" _hover={{ backgroundColor: '#edf2f7' }}>
                  <Icon as={IoReturnDownBackSharp} boxSize="5" />
                  <Text>Logout</Text>
                </HStack>
              </Box>
              <Spacer />
              <Spacer />
              <Spacer />
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ProfileMenuModal

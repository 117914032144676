import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useDeclineFollowingRequestMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'decline-following-request',
    async ({ followingRequestReceivedId }: { followingRequestReceivedId: string }) => {
      await Api.declineFollowingRequest(followingRequestReceivedId)
    },
    { ...options },
  )
}

export default useDeclineFollowingRequestMutation

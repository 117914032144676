import Api from '@src/services/api/api.service'
import { EventDoneDTO } from '@src/typings/database'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

interface IUseGetDoneEventsByUserParams {
  userId: string
  profileId: string
  options?: UseQueryOptions<unknown, AxiosError, EventDoneDTO>
}

const useGetDoneEventsByUser = ({ profileId, userId, options }: IUseGetDoneEventsByUserParams) => {
  const isMe = profileId === userId
  const queryKey = isMe ? 'currentUserDoneEvents' : `userDoneEvents#${profileId}`

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = isMe ? await Api.currentUserDoneEvents() : await Api.userDoneEvents(profileId)
      return response.data
    },
    enabled: !!userId && !!profileId,
    ...options,
  })
}

export default useGetDoneEventsByUser

import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react'
import ScanQRCodeComponent from '@src/components/shared/scan-qr-code'
import { Attendance } from '@src/typings/database'
import { selectorAuth } from 'features/auth/auth.slice'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { ReactComponent as BoltsIcon } from './../../assets/bolts.svg'
import EventConfirmAttendance from './event-confirm-attendance'
import useCreateAttendance from '../../hooks/use-create-attendance'
import { useFormbitContext } from '@radicalbit/formbit'
import { EventScreenSchema } from './event-screen-schema'

const EventButtons = () => {
  const { write } = useFormbitContext<EventScreenSchema>()
  const { handleCreateAttendance } = useCreateAttendance()

  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendanceCost = data?.attendanceCost
  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const isApproved = !!attendance?.approvedAt

  const endDate = data?.endDate
  const isAfterEndDate = moment(endDate).isAfter()
  const isBeforeEndDate = moment(endDate).isBefore()

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const goToEventRating = () => {
    if (!user) return
    window.open(`https://tally.so/r/mYr85q?email=${user.email}&eventId=${id}`, '_blank')
  }

  const handleOnClick = async () => {
    await handleCreateAttendance()
  }

  const handleOnQrOpen = () => {
    write('__metadata.isQROpen', true)
  }

  return (
    <Box zIndex="100">
      <EventConfirmAttendance />

      <Flex direction="row" position="fixed" left="0" bottom="80px" marginBottom="env(safe-area-inset-bottom)" width="100%" mx="auto" zIndex="20">
        <Spacer />

        {isOrganizer && isAfterEndDate && <ScanQRCodeComponent />}

        {!isOrganizer && !attendance && isAfterEndDate && (
          <Button
            width="fit-content"
            height="50px"
            variant="solid"
            px="20px"
            rightIcon={user !== null ? <BoltsIcon /> : <></>}
            onClick={handleOnClick}>
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Partecipa {user !== null && `-${attendanceCost}`}
            </Text>
          </Button>
        )}

        {!isOrganizer && attendance && !isApproved && isAfterEndDate && (
          <Button width="fit-content" height="50px" variant="solid" rightIcon={<BoltsIcon />} onClick={handleOnQrOpen} px="20px">
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Conferma presenza +2
            </Text>
          </Button>
        )}

        {attendance && isApproved && isBeforeEndDate && (
          <Button width="fit-content" height="50px" variant="solid" onClick={goToEventRating} px="20px">
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Valuta evento
            </Text>
          </Button>
        )}
        <Spacer />
      </Flex>
    </Box>
  )
}

export default EventButtons

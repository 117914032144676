import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

interface IUseGetFollowers {
  profileId: string
  userId: string
  options?: UseQueryOptions<unknown, AxiosError, any> //UserDTO>
}

const useGetFollowers = ({ profileId, userId, options }: IUseGetFollowers) => {
  const isMe = profileId === userId

  return useQuery({
    queryKey: 'followers',
    queryFn: async () => {
      const response = isMe ? await Api.currentFollowings() : await Api.getUserFollowings(profileId)
      return response.data
    },
    enabled: !!profileId && !!userId,
    ...options,
  })
}

export default useGetFollowers

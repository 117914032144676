import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { EventScreenSchema } from './event-screen-schema'

const EventCostModal = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data } = useGetEventById({ eventId: id })
  const organizerId = data?.organizer?._id

  const { form, write } = useFormbitContext<EventScreenSchema>()
  const isEventCostModalOpen = form?.__metadata?.isEventCostModalOpen as boolean

  const goToOrganizerProfile = () => {
    write('__metadata.isEventCostModalOpen', false)
    navigate(`/profile/${organizerId}`)
  }

  const handleOnClose = () => {
    write('__metadata.isEventCostModalOpen', false)
  }

  return (
    <Modal isCentered isOpen={isEventCostModalOpen} onClose={handleOnClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent ml="10px" mr="10px">
        <ModalHeader fontWeight="bold">Come pagare?</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>
          L'eventuale importo da pagare è deciso dall'organizzatore così come le modalità di pagamento. Sbam! non gestisce i pagamenti né trattiene
          commissioni (art.14 T&C)
        </ModalBody>
        <ModalFooter>
          <Button
            fontWeight="600"
            fontFamily="Poppins"
            textAlign="center"
            variant="solid"
            height="40px"
            width="100%"
            fontSize="17px"
            mr={3}
            onClick={goToOrganizerProfile}>
            Chiedi all'organizzatore
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EventCostModal

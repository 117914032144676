import * as yup from 'yup'

const eventScreenSchema = yup.object({
    __metadata: yup.object({
        copied: yup.boolean(),
        isSocialOpen: yup.boolean(),
        isDeleteOpen: yup.boolean(),
        isDeleteAttendanceOpen: yup.boolean(),
        cancelDeleteAttendanceRef: yup.mixed(),
        isCompleteProfileOpen: yup.boolean(),
        isQROpen: yup.boolean(),
        isUnblockChatModalOpen: yup.boolean(),
        isEventCostModalOpen: yup.boolean()
    })
})

export type EventScreenSchema = yup.InferType<typeof eventScreenSchema>
export default eventScreenSchema

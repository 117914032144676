import { Clipboard } from '@capacitor/clipboard'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import React, { useRef } from 'react'
import { EventScreenSchema } from '../../event-screen-schema'

const ShareEventDialog = () => {
  const { form, writeAll } = useFormbitContext<EventScreenSchema>()
  const isSocialOpen = form?.__metadata?.isSocialOpen ?? false

  const cancelSocialRef = useRef(null)

  const onSocialClose = () => {
    writeAll([
      ['__metadata.copied', false],
      ['__metadata.isSocialOpen', false],
    ])
  }

  return (
    <AlertDialog isCentered isOpen={isSocialOpen} leastDestructiveRef={cancelSocialRef} onClose={onSocialClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <ShareEventDialogHeader />

          <ShareEventDialogBody />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const ShareEventDialogHeader = () => {
  return (
    <>
      <AlertDialogHeader fontWeight="bold">Condividi sui social</AlertDialogHeader>
      <ModalCloseButton size="lg" />
    </>
  )
}
const ShareEventDialogBody = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()
  const copied = form?.__metadata?.copied

  const copyLink = async () => {
    await Clipboard.write({
      url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
    })
    write('__metadata.copied', true)
  }

  return (
    <AlertDialogBody>
      <br />
      <Button
        fontWeight="600"
        fontFamily="Poppins"
        textAlign="center"
        height="40px"
        width="100%"
        fontSize="17px"
        onClick={copyLink}
        mb="10px"
        variant="outline">
        Copia link{' '}
        {copied && (
          <Text color="gray" fontSize="17px" ml="15px">
            copiato
          </Text>
        )}
      </Button>
    </AlertDialogBody>
  )
}

export default ShareEventDialog

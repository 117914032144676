import { useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { queryClient } from '@src/query-client'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useCancelFollowingRequestMutation from './mutations/use-cancel-following-request-mutation'
import useGetProfileById from './queries/use-get-profile-by-id'

const useHandleCancelFollowingRequest = () => {
  const toast = useToast()

  const { user } = useSelector(selectorAuth)
  const { id } = useParams()
  const userId = id || user._id

  const { isLoading: profileIsLoading, data } = useGetProfileById({ profileId: userId })
  const followingSentRequestId = data?.followingRequestSent?._id

  const { isLoading, mutateAsync } = useCancelFollowingRequestMutation({
    options: {
      onSuccess: () => {
        toast({
          title: 'Richiesta di following annullata',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        })
        queryClient.invalidateQueries([`followers`])
        queryClient.invalidateQueries([`profile${userId}`])
      },
      onError: err => {
        toast({
          title: 'Error', //err?.response?.data?.message ||
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        return
      },
    },
  })

  const status = profileIsLoading || isLoading

  const handleCancelFollowingRequest = async () => {
    if (isLoading || profileIsLoading || !followingSentRequestId) {
      return
    }

    await mutateAsync({ followingSentRequestId })
  }

  return { handleCancelFollowingRequest, isLoading: status }
}

export default useHandleCancelFollowingRequest

import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useApproveAttendanceMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'approve-attendance',
    async ({ eventId, attendanceId, attendanceCode }: { eventId: string; attendanceId: string; attendanceCode: string }) => {
      await Api.approveAttendance(eventId!, attendanceId, attendanceCode)
    },
    {
      ...options,
    },
  )
}

export default useApproveAttendanceMutation

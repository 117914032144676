import { useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { queryClient } from '@src/query-client'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useAcceptFollowingRequestMutation from './mutations/use-accept-following-request-mutation'
import useGetProfileById from './queries/use-get-profile-by-id'

const useHandleAcceptFollowingRequest = () => {
  const toast = useToast()

  const { user } = useSelector(selectorAuth)
  const { id } = useParams()
  const userId = id || user._id

  const { isLoading: profileIsLoading, data } = useGetProfileById({ profileId: userId })
  const followingSentRequestReceivedId = data?.followingRequestReceived?._id

  const { isLoading, mutateAsync } = useAcceptFollowingRequestMutation({
    options: {
      onSuccess: () => {
        toast({
          title: 'Richiesta di following accettata',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        })
        queryClient.invalidateQueries([`followers`])
        queryClient.invalidateQueries([`profile${userId}`])
      },
      onError: err => {
        toast({
          title: 'Error', //err?.response?.data?.message ||
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        return
      },
    },
  })

  const status = profileIsLoading || isLoading

  const handleAcceptFollowingRequest = async () => {
    if (isLoading || profileIsLoading || !followingSentRequestReceivedId) {
      return
    }

    await mutateAsync({ followingSentRequestReceivedId })
  }

  return { handleAcceptFollowingRequest, isLoading: status }
}

export default useHandleAcceptFollowingRequest

import { useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { queryClient } from '@src/query-client'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useDeleteFollowingMutation from './mutations/use-delete-following-mutation'
import useGetProfileById from './queries/use-get-profile-by-id'

const useHandleDeleteFollowing = () => {
  const toast = useToast()

  const { user } = useSelector(selectorAuth)
  const { id } = useParams()
  const userId = id || user._id

  const { isLoading: profileIsLoading, data } = useGetProfileById({ profileId: userId })
  const profileFollowingId = data?.following?._id
  const profileFollowerId = data?.follower?._id

  const { isLoading, mutateAsync } = useDeleteFollowingMutation({
    options: {
      onSuccess: () => {
        toast({
          title: 'Utente rimosso',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        })
        queryClient.invalidateQueries([`followers`])
        queryClient.invalidateQueries([`profile${userId}`])
      },
      onError: err => {
        toast({
          title: 'Error', //err?.response?.data?.message ||
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        return
      },
    },
  })

  const status = profileIsLoading || isLoading

  const handleDeleteFollowing = async ({ doIFollowUser }: { doIFollowUser: boolean }) => {
    const followingId = doIFollowUser ? profileFollowingId : profileFollowerId

    if (isLoading || profileIsLoading || !followingId) {
      return
    }

    await mutateAsync({ followingId })
  }

  return { handleDeleteFollowing, isLoading: status }
}

export default useHandleDeleteFollowing

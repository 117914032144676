import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useSendFollowingRequestMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'send-following-request',
    async ({ userId }: { userId: string }) => {
      await Api.sendFollowingRequest({ data: { userId } })
    },
    { ...options },
  )
}

export default useSendFollowingRequestMutation

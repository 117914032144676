import { Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import QrCodeSvg from 'components/shared/qr-code/qr-code-svg'
import React from 'react'
import { EventScreenSchema } from './event-screen-schema'
import { Attendance } from '@src/typings/database'
import { selectorAuth } from '@features/auth/auth.slice'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'

const EventConfirmAttendance = () => {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const { form, write } = useFormbitContext<EventScreenSchema>()
  const isQrOpen = form?.__metadata?.isQROpen as boolean

  const handleOnClose = () => {
    write('__metadata.isQROpen', false)
  }

  return (
    <Modal isCentered isOpen={isQrOpen} onClose={handleOnClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent mr="10px" ml="10px">
        <ModalHeader fontWeight="bold">Conferma la tua presenza</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody pb="40px">
          <Flex direction="column" gap=".5rem">
            <Center>
              <QrCodeSvg
                size={Math.min(window.innerWidth, 560) * 0.6}
                level={'L'}
                value={JSON.stringify({ attendanceId: attendance?._id, attendanceCode: attendance?.code })}
              />
            </Center>

            <Text textAlign="left" fontSize="15px" mb="2">
              Fai scansionare questo codice all'organizzatore per confermare la tua presenza e ottenere saette bonus.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EventConfirmAttendance

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { Alert } from 'components/shared/confirm/confirm'
import React, { useRef } from 'react'
import Api from 'services/api/api.service'
import { EventScreenSchema } from '../../event-screen-schema'
import useGetEventById from '@src/screens/chat/hooks/use-get-event-by-id'
import { useNavigate, useParams } from 'react-router'
import { selectorAuth } from '@features/auth/auth.slice'
import { useSelector } from 'react-redux'

const EventDeletionDialog = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()
  const isDeleteOpen = form?.__metadata?.isDeleteOpen ?? false

  const cancelDeleteRef = useRef(null)

  const onDeleteClose = () => {
    write('__metadata.isDeleteOpen', false)
  }

  return (
    <AlertDialog isCentered isOpen={isDeleteOpen} leastDestructiveRef={cancelDeleteRef} onClose={onDeleteClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <EventDeletionDialogHeader />

          <EventDeletionDialogBody />

          <EventDeletionDialogFooter />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const EventDeletionDialogHeader = () => {
  return (
    <>
      <AlertDialogHeader fontWeight="bold">Cancella evento</AlertDialogHeader>
      <ModalCloseButton size="lg" />
    </>
  )
}

const EventDeletionDialogBody = () => {
  return <AlertDialogBody>Sei sicuro? Questa azione non può essere annullata.</AlertDialogBody>
}

const EventDeletionDialogFooter = () => {
  const { write } = useFormbitContext<EventScreenSchema>()

  const navigate = useNavigate()
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const confirmDeleteEvent = async () => {
    if (isOrganizer && id) {
      try {
        await Api.deleteEvent(id)
        navigate('/', { replace: true })
      } catch (e) {
        await Alert({
          title: 'Errore',
          message: `L'evento potrebbe essere in corso/passato o non essere più disponibile.`,
          confirmText: 'Ho capito',
        })
      }
    }
  }

  const onDeleteClose = () => {
    write('__metadata.isDeleteOpen', false)
  }

  return (
    <AlertDialogFooter>
      <Button bg="white" color="black" onClick={onDeleteClose} height="30px" width="100%" fontSize="17px">
        Annulla
      </Button>
      <Button
        fontWeight="600"
        fontFamily="Poppins"
        textAlign="center"
        variant="solid"
        height="40px"
        width="100%"
        fontSize="17px"
        onClick={confirmDeleteEvent}
        ml={3}>
        Cancella evento
      </Button>
    </AlertDialogFooter>
  )
}

export default EventDeletionDialog

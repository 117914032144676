import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import { FormbitContextProvider } from '@radicalbit/formbit'
import CompleteProfileModal from 'components/shared/complete-profile-modal/complete-profile-modal'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import React from 'react'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import EventButtons from './event-buttons'
import EventCost from './event-cost'
import EventCover from './event-cover'
import EventDate from './event-date'
import EventLocation from './event-location'
import EventParticipants from './event-participants'
import EventParticipantsInfo from './event-participants-info'
import EventScreenHeader from './event-screen-header'
import schema from './event-screen-schema'
import UnblockChatModal from './unblock-chat-modal'
import EventCostModal from './event-cost-modal'

const initialValues = {
  __metadata: {
    copied: false,
    isSocialOpen: false,
    isDeleteOpen: false,
    isDeleteAttendanceOpen: false,
    cancelDeleteAttendanceRef: null,
    isCompleteProfileOpen: false,
    isQROpen: false,
    isUnblockChatModalOpen: false,
    isEventCostModalOpen: false,
  },
}

const EventScreen = () => {
  return (
    <FormbitContextProvider initialValues={initialValues} schema={schema}>
      <EventScreenInner />
    </FormbitContextProvider>
  )
}

const EventScreenInner = () => {
  const toast = useToast()

  const { id } = useParams()
  const { isLoading, error, data } = useGetEventById(
    { eventId: id },
    {
      onError: () => {
        toast({
          title: error?.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      },
    },
  )

  const title = data?.title
  const eventCost = data?.cost
  const eventDescription = data?.description

  if (isLoading || !title) return <></>

  return (
    <>
      <Wrapper height="100%" overflowX="hidden">
        <EventScreenHeader />

        <EventCover />

        <Flex direction="column" gap="2rem">
          <Flex direction="column" gap="1rem">
            <EventLocation />
            <EventDate />
            {!!eventCost && <EventCost />}
          </Flex>

          {eventDescription && (
            <Text as="i" fontFamily="Poppins" fontWeight="400" fontSize="15px" textColor="rgb(70, 83, 98)">
              {eventDescription}
            </Text>
          )}

          <Divider borderColor="#465362" />

          <EventParticipantsInfo />

          <EventParticipants />

          <Box width="100%" height="2rem" />

          <EventButtons />
        </Flex>
      </Wrapper>

      <CompleteProfileModal />

      <UnblockChatModal />

      <EventCostModal />
    </>
  )
}

export default EventScreen

import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useAddChatRoomOneToOneMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'add-chat-room-one-to-one',
    async ({ userId }: { userId: string }) => {
      return await Api.addChartRoomOneToOne(userId)
    },
    { ...options },
  )
}

export default useAddChatRoomOneToOneMutation

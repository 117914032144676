import { ReactComponent as BanCard } from '@assets/ban-card.svg'
import { ReactComponent as BoltsIcon } from '@assets/bolts.svg'
import { ReactComponent as CalendarIcon } from '@assets/calendar-icon.svg'
import { ReactComponent as ChatIcon } from '@assets/chat.svg'
import { ReactComponent as CloseIcon } from '@assets/close-icon.svg'
import { ReactComponent as GroupIcon } from '@assets/group-icon.svg'
import { ReactComponent as PlusIcon } from '@assets/plus.svg'
import { ReactComponent as ReferralIcon } from '@assets/referral-logo.svg'
import { ReactComponent as UserIcon } from '@assets/user.svg'
import { ReactComponent as WarningIcon } from '@assets/warning-icon.svg'
import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { Avatar, Box, Button, Collapse, Flex, HStack, Icon, Text, useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import useIsMe from '@hooks/use-is-me'
import { useFormbitContext } from '@radicalbit/formbit'
import { AdvBanner } from '@src/components/shared/adv-banner'
import EventPreview from '@src/components/shared/event-preview/event-preview.component'
import { PAGE_ENUM, WarningTypeModel } from '@src/costants'
import { UserStatus } from '@src/typings/database'
import { ReactComponent as AdmonitionCard } from 'assets/admonition-card.svg'
import React, { Fragment } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdShare } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useHandleAcceptFollowingRequest from './hooks/handle-accept-following-request'
import useHandleCancelFollowingRequest from './hooks/handle-cancel-following-request'
import useHandleDeclineFollowingRequest from './hooks/handle-decline-following-request'
import useHandleDeleteFollowing from './hooks/handle-delete-following'
import useHandleSendFollowingRequest from './hooks/handle-send-following-request'
import useAddChatRoomOneToOneMutation from './hooks/mutations/use-add-chat-room-one-to-one-mutation'
import useGetDoneEventsByUser from './hooks/queries/use-get-done-events-by-user'
import useGetProfileById from './hooks/queries/use-get-profile-by-id'
import { NewProfileSchema } from './schema'
import useAmIAdminOrSupport from '@hooks/use-am-i-admin-or-support'

const DeleteFollowingButton = () => {
  const { handleDeleteFollowing } = useHandleDeleteFollowing()

  return (
    <Button
      variant="outline_white"
      height="38px"
      leftIcon={
        <Box>
          <Icon boxSize="5" as={UserIcon} mr="-1" />
          <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
        </Box>
      }
      onClick={() => {
        handleDeleteFollowing({ doIFollowUser: false })
      }}>
      <Text color="brand.500" fontSize="12px">
        Rimuovi follower
      </Text>
    </Button>
  )
}

const DoNotFollowingMoreButton = () => {
  const { handleDeleteFollowing } = useHandleDeleteFollowing()

  const handleOnClick = () => {
    handleDeleteFollowing({ doIFollowUser: true })
  }

  return (
    <Button
      variant="outline_white"
      height="38px"
      leftIcon={
        <Box>
          <Icon boxSize="5" as={UserIcon} mr="-1" />
          <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
        </Box>
      }
      onClick={handleOnClick}>
      <Text color="brand.500" fontSize="16px">
        Non seguire più
      </Text>
    </Button>
  )
}

const AcceptFollowingRequestButton = () => {
  const { handleAcceptFollowingRequest } = useHandleAcceptFollowingRequest()

  return (
    <Button
      bgColor="brand.500"
      height="38px"
      leftIcon={
        <Box mr="-1" mt="0.5">
          <Icon boxSize="5" as={PlusIcon} />
        </Box>
      }
      onClick={handleAcceptFollowingRequest}>
      <Text color="white" fontSize="16px">
        Accetta richiesta
      </Text>
    </Button>
  )
}

const DeclineFollowingRequestButton = () => {
  const { handleDeclineFollowingRequest } = useHandleDeclineFollowingRequest()

  return (
    <Button
      variant="outline_white"
      height="38px"
      leftIcon={
        <Box mr="-1" mt="0.5">
          <Icon boxSize="5" as={PlusIcon} transform="rotate(45deg)" />
        </Box>
      }
      onClick={handleDeclineFollowingRequest}>
      <Text color="brand.500" fontSize="16px">
        Rifiuta richiesta
      </Text>
    </Button>
  )
}

const AcceptDeclineFollowingRequestContainer = () => {
  return (
    <HStack spacing="2">
      <DeclineFollowingRequestButton />
      <AcceptFollowingRequestButton />
    </HStack>
  )
}

const CancelFollowingRequestLightButton = () => {
  const { handleCancelFollowingRequest } = useHandleCancelFollowingRequest()

  return (
    <Button
      variant="outline_white"
      height="38px"
      leftIcon={
        <Box mt="1">
          <Icon color="brand.500" boxSize="5" as={UserIcon} mr="-1" />
          <Icon color="brand.500" boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
        </Box>
      }
      onClick={handleCancelFollowingRequest}>
      <Text color="brand.500" fontSize="16px">
        Annulla richiesta
      </Text>
    </Button>
  )
}

const CancelFollowingRequestDarkButton = () => {
  const { handleCancelFollowingRequest } = useHandleCancelFollowingRequest()
  return (
    <Button
      variant="solid"
      height="38px"
      leftIcon={
        <Box>
          <Icon color="white" boxSize="5" as={UserIcon} mr="-1" />
          <Icon color="white" boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
        </Box>
      }
      onClick={handleCancelFollowingRequest}>
      <Text color="white" fontSize="12px">
        Annulla richiesta
      </Text>
    </Button>
  )
}

const SendFollowingRequestButton = () => {
  const { handleSendFollowingRequest } = useHandleSendFollowingRequest()

  return (
    <Button
      bgColor="brand.500"
      height="38px"
      leftIcon={
        <Box mt="1">
          <Icon boxSize="5" as={UserIcon} mr="-1" />
          <Icon boxSize="4" as={PlusIcon} mb="1" />
        </Box>
      }
      onClick={handleSendFollowingRequest}>
      <Text color="white" fontSize="16px">
        Segui profilo
      </Text>
    </Button>
  )
}

const AddFriendsButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      bgColor="brand.500"
      height="38px"
      leftIcon={
        <Box mr="1" mt="0.5" position="relative">
          <Icon boxSize="5" as={ReferralIcon} />
          <Icon boxSize="5" as={BoltsIcon} position="absolute" bottom="2" left="13px" />
        </Box>
      }
      onClick={() => {
        navigate('/referral')
      }}>
      <Text color="white" fontSize="16px">
        Invita amici
      </Text>
    </Button>
  )
}

const MoreFollowingActionMenu = () => {

  const { form, write } = useFormbitContext<NewProfileSchema>()
  const isDisclosureOpen = form?.__metadata?.isDisclosureOpen ?? false

  const { handleDeleteFollowing } = useHandleDeleteFollowing()

  const handleOnToggle = () => {
    write('__metadata.isDisclosureOpen', !isDisclosureOpen)
  }

  const handleRemoveFollower = () => {
    handleDeleteFollowing({ doIFollowUser: false })
  }

  const handleDoNotFollow = () => {
    handleDeleteFollowing({ doIFollowUser: true })
  }

  return (
    <>
      <HStack width="100%" spacing="1">
        <Button variant="outline_white" height="38px" onClick={handleOnToggle} width="20%">
          <Flex direction="row" align="center" justify="center">
            <Icon boxSize="5" as={UserIcon} mr="-1" />
            <Icon boxSize="4" as={PlusIcon} mb="1" transform="rotate(45deg)" />
          </Flex>
        </Button>

        <SendMessageToUser />

      </HStack>

      <Collapse in={isDisclosureOpen} animateOpacity>
        <Box position="relative" padding="3" borderStyle="solid" borderWidth="1px" borderColor="black" borderRadius="4px">
          <Box position="absolute" top="5px" right="5px">
            <Icon as={CloseIcon} onClick={handleOnToggle} />
          </Box>
          <Flex direction="column">
            <Text align="left" mb="3" _hover={{ textDecoration: 'underline' }} onClick={handleRemoveFollower}>
              Rimuovi follower
            </Text>
            <Text align="left" _hover={{ textDecoration: 'underline' }} onClick={handleDoNotFollow}>
              Smetti di seguire
            </Text>
          </Flex>
        </Box>
      </Collapse>
    </>
  )
}

const SendMessageToUser = () => {
  const isAdminOrSupport = useAmIAdminOrSupport()
  const navigate = useNavigate()

  const { profileId } = useIsMe()
  const { data: profileData } = useGetProfileById({ profileId })
  const iFollowUser = profileData?.following
  const existingUserChat = profileData?.existingUserChat
  const chatSid = existingUserChat ? existingUserChat.chatSid : iFollowUser?.chatSid
  
  const showOpenChatOneToOne = isAdminOrSupport && existingUserChat === null
  const showSendMessage = existingUserChat !== null
  
  const handleOnClick = () => {
    navigate(`/event/${chatSid}/chat`)
  }
  
  if(showSendMessage){
    return (
      <Button bgColor="brand.500" height="38px" leftIcon={<Icon color="white" boxSize="5" as={ChatIcon} />} onClick={handleOnClick} width='100%' >
        <Text color="white" fontSize="15px">
          Invia messaggio
        </Text>
      </Button>
    )
  }

  if(showOpenChatOneToOne){
    return <OpenOneToOneChatButton />
  }

  return <></>


}

const EventsParticipatesAndOrganizesButton = () => {
  const { write } = useFormbitContext()

  const navigate = useNavigate()

  const { user } = useSelector(selectorAuth)
  const avatar = user?.avatar

  const onOrganizeEvent = async () => {
    if (avatar) {
      navigate('/event/create/wizard')
    } else {
      write('__metadata.isCompleteProfileOpen', true)
    }
  }

  return (
    <Flex justify="space-around" alignContent="center" mt="20px" gap={2}>
      <Link to="/">
        <Button
          variant="outline_white"
          height="35px"
          leftIcon={
            <Box position="relative">
              <Icon as={CalendarIcon} />
              <Icon as={GroupIcon} boxSize="10px" position="absolute" left="5px" top="7px" />
            </Box>
          }>
          <Text fontSize="17px">Partecipa</Text>
        </Button>
      </Link>
      <Button
        bgColor="brand.500"
        height="35px"
        onClick={onOrganizeEvent}
        leftIcon={
          <Box position="relative">
            <Icon as={CalendarIcon} color="white" />
            <Icon as={PlusIcon} boxSize="10px" position="absolute" left="5px" top="8px" />
          </Box>
        }>
        <Text fontSize="17px">Organizza</Text>
      </Button>
    </Flex>
  )
}

const DeleteCancelSendFollowingRequestContainer = () => {
  const { profileId } = useIsMe()

  const { data: profileData } = useGetProfileById({ profileId })
  const iSentRequest = profileData?.followingRequestSent

  return (
    <>
      <HStack spacing="1">
        <DeleteFollowingButton />

        {iSentRequest && <CancelFollowingRequestDarkButton />}

        {!iSentRequest && <SendFollowingRequestButton />}

      </HStack>
      <SendMessageToUser/>
    </>
  )
}

const FollowerInstructions = () => {
  return (
    <Box>
      <Text textAlign="center">Puoi vedere follower, seguiti e eventi solo di chi ha accettato la tua richiesta di poterlə seguire.</Text>
      <Text textAlign="center">Puoi chattare in privato con una persona solo se entrambi vi seguite a vicenda.</Text>
    </Box>
  )
}

const EventInstructions = () => {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const profileId = id || user._id
  const isMe = profileId === user._id

  const { data: eventsData } = useGetDoneEventsByUser({ profileId, userId: user._id })

  const { data: profileData } = useGetProfileById({ profileId })
  const iFollowUser = profileData?.following

  const { form } = useFormbitContext()
  const page = form?.__metadata?.page

  const events = page === PAGE_ENUM.PAST ? eventsData?.past : eventsData?.future

  const isMeText =
    page === PAGE_ENUM.PAST
      ? 'Gli eventi a cui hai partecipato o che hai organizzato appariranno qui.'
      : 'Gli eventi a cui parteciperai o che organizzerai appariranno qui.'

  const text =
    page === PAGE_ENUM.PAST
      ? "Gli eventi a cui l'utente ha partecipato o che ha organizzato appariranno qui."
      : "Gli eventi a cui l'utente parteciperà o che organizzerà appariranno qui."

  if (events && !events.length && isMe) {
    return <Text textAlign="center">{isMeText}</Text>
  }

  if (events && !events.length && !isMe && iFollowUser) {
    return <Text textAlign="center">{text}</Text>
  }

  return <></>
}

const EventsPreview = () => {
  const { profileId, userId } = useIsMe()

  const { form } = useFormbitContext<NewProfileSchema>()
  const page = form?.__metadata?.page

  const { data: eventsData } = useGetDoneEventsByUser({ profileId, userId })
  const events = page === PAGE_ENUM.PAST ? eventsData?.past : eventsData?.future

  return (
    <Flex flex={1} direction="column" gap=".8rem">
      <>
        {events?.map((event, index: number) => (
          <Fragment key={event._id}>
            <Link to={'/event/' + event._id}>
              <EventPreview {...event} />
            </Link>
            {index > 0 && (index + 1) % 3 === 0 && <AdvBanner key={event._id + '_banner'} />}
          </Fragment>
        ))}
      </>
    </Flex>
  )
}

const Header = () => {
  const toast = useToast()
  const { write } = useFormbitContext<NewProfileSchema>()

  const { id } = useParams()
  const { user } = useSelector(selectorAuth)
  const profileId = id || user._id

  const { data } = useGetProfileById({ profileId })
  const firstName = data?.firstName
  const lastName = data?.lastName
  const avatar = data?.avatar
  const role = data?.role

  const isMe = profileId === user._id

  const shareProfile = async () => {
    if (!Capacitor.isNativePlatform()) {
      await Clipboard.write({ url: `${process.env.REACT_APP_APP_URL}/profile/${profileId}` })
      toast({
        title: 'URL profilo copiato in clipboard',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    } else {
      await Share.share({
        dialogTitle: 'Condividi il tuo profilo sui social',
        title: `${firstName} ${lastName}`,
        text: '',
        url: `${process.env.REACT_APP_APP_URL}/profile/${profileId}`,
      })
    }
  }

  const handleOpenProfileMenuModal = () => {
    write('__metadata.isProfileMenuOpen', true)
  }

  return (
    <Flex justifyContent="space-between" pl="20px" pr="20px">
      <Box position="relative" onClick={shareProfile} cursor="pointer">
        <Icon boxSize="1.6em" position="relative" as={IoMdShare} _hover={{ color: 'brand.500' }} />
      </Box>

      <Box position="relative">
        <Avatar src={avatar} size="xl" borderWidth="3px" borderColor="brand.500" />
        {role && (
          <Flex backgroundColor="black" color="white" borderRadius=".5rem" justifyContent="center" position="absolute" width="100%" top="85%">
            <Text fontWeight="bold" fontSize="sm">
              {role}
            </Text>
          </Flex>
        )}
      </Box>

      <Box>
        {isMe && (
          <Box onClick={handleOpenProfileMenuModal} cursor="pointer">
            <Icon boxSize="1.6em" as={GiHamburgerMenu} _hover={{ color: 'brand.500' }} />
          </Box>
        )}
        {!isMe && (
          <Box
            cursor="pointer"
            top="0"
            right="0"
            onClick={() => {
              window.open('https://sbam.social/supporto', '_blank')
            }}>
            <Icon boxSize="1.6em" as={WarningIcon} _hover={{ color: 'brand.500' }} />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

const HeaderUserDetail = () => {
  const { write } = useFormbitContext<NewProfileSchema>()
  const navigate = useNavigate()

  const { id } = useParams()
  const { user } = useSelector(selectorAuth)
  const profileId = id || user._id
  const isMe = profileId === user._id

  const { data } = useGetProfileById({ profileId })
  const firstName = data?.firstName
  const lastName = data?.lastName
  const bioDescription = data?.bioDescription
  const warnings = data?.warnings

  const activeWarning = warnings?.find(w => new Date(w.expiresAt!).getTime() > Date.now())
  const userStatus: UserStatus | undefined = activeWarning ? (activeWarning.type as UserStatus) : undefined

  const handleOpenAdmonitionInfo = () => {
    if (isMe) {
      navigate('/monition')
    } else {
      write('__metadata.isAdmonitionInfoOpen', true)
    }
  }

  const handleOpenBanInfo = () => {
    if (isMe) {
      navigate('/ban')
    } else {
      write('__metadata.isBanInfoOpen', true)
    }
  }

  if (userStatus === WarningTypeModel.Admonition) {
    return (
      <Box>
        <Text align="center" fontWeight="semibold" fontSize="lg">
          {firstName} {lastName} <Icon onClick={handleOpenAdmonitionInfo} as={AdmonitionCard} />
        </Text>
        <Text align="center">{bioDescription}</Text>
      </Box>
    )
  }

  if (userStatus === WarningTypeModel.Ban) {
    return (
      <Box>
        <Text align="center" fontWeight="semibold" fontSize="lg">
          {firstName} {lastName} <Icon onClick={handleOpenBanInfo} as={BanCard} />
        </Text>
        <Text align="center">{bioDescription}</Text>
      </Box>
    )
  }

  return (
      <Box>
        <Text align="center" fontWeight="semibold" fontSize="lg">
          {firstName} {lastName}
        </Text>
        <Text align="center">
          {bioDescription}
        </Text>
      </Box>
  )
}

const OpenOneToOneChatButton = () => {
  const navigate = useNavigate()
  const {showToast} = useCommonToast()

  const { id } = useParams()


  const { mutateAsync } = useAddChatRoomOneToOneMutation({options: {
    onError:(err) => {
      showToast({title : (err?.response?.data as any)?.message})
    }
  }})

  const handleOnClick = async () => {
    const { data } = await mutateAsync({ userId: id! })
    const chatSid = data?.chatSid
    navigate(`/event/${chatSid}/chat`)
  }

  return (
    <>
      <Button height="38px" width="100%" leftIcon={<Icon boxSize="5" as={ChatIcon} />} fontSize={14} variant="outline" onClick={handleOnClick}>
        Apri chat room
      </Button>
    </>
  )
}

const useCommonToast = () => {
  const toast = useToast()

  const showToast = ({ title }: { title: string }) => {
    toast({
      title,
      status: 'error',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
    })
  }

  return { showToast }
}

export {
  AcceptDeclineFollowingRequestContainer,
  AddFriendsButton,
  CancelFollowingRequestLightButton,
  DeleteCancelSendFollowingRequestContainer,
  DoNotFollowingMoreButton,
  EventInstructions,
  EventsParticipatesAndOrganizesButton,
  EventsPreview,
  FollowerInstructions,
  Header,
  HeaderUserDetail,
  MoreFollowingActionMenu,
  SendMessageToUser,
  SendFollowingRequestButton,
  useCommonToast,
}

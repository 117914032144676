import { ReactComponent as CalendarIcon } from '@assets/calendar.svg'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import moment from 'moment'
import React from 'react'
import './style.css'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { Attendance } from '@src/typings/database'
import { selectorAuth } from '@features/auth/auth.slice'
import { useSelector } from 'react-redux'

const EventDate = () => {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const startDate = data?.startDate
  const endDate = data?.endDate
  const coverUrl = data?.coverUrl
  const address = data?.location?.details?.address
  const eventTitle = data?.title

  const eventDescription = data?.description

  const momentStartDate = moment(startDate)
  const day = momentStartDate.format('DD')
  const month = momentStartDate.format('MMMM')
  const year = momentStartDate.format('YYYY')
  const time = momentStartDate.format('LT')

  const isAfterStartDate = moment(startDate).isAfter()

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  return (
    <Flex direction="row" alignItems="center" justifyContent="space-between">
      <Flex direction="row" alignItems="center">
        <Icon mr="2" as={CalendarIcon} boxSize="20px" color="#673737" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" lineHeight="22.5px" mr="2">
          il{' '}
          <Text fontWeight="600" display="inline">
            {' '}
            {day} {month[0].toUpperCase() + month.slice(1)}{' '}
          </Text>{' '}
          {year} alle{' '}
          <Text fontWeight="600" display="inline">
            {time}
          </Text>
        </Text>
      </Flex>

      {isAfterStartDate && attendance ? (
        <AddToCalendarButton
          description={eventDescription}
          endDate={endDate ? moment(endDate).format('YYYY-MM-DD') : undefined}
          endTime={endDate ? moment(endDate).format('HH:mm') : undefined}
          images={coverUrl}
          label="Agenda"
          location={address}
          name={eventTitle}
          options={['Google']}
          startDate={momentStartDate.format('YYYY-MM-DD')}
          startTime={momentStartDate.format('HH:mm')}
          timeZone="Europe/Rome"
          hideIconButton
          hideCheckmark
          styleLight="--btn-background: white; --btn-border: #FF7436; --btn-border-radius: 10px; --btn-shadow: none; --btn-text: #FF7436; --font: Poppins; --btn-font-weight: 500; --btn-padding-x: 9; --wrapper-padding: 1px; --base-font-size-l: 12px; --base-font-size-m: 12px; --base-font-size-s: 12px; --btn-hover-shadow: none; --btn-hover-background: #e3e3e3; --btn-hover-text: #FF7436; --btn-active-shadow: none"
          lightMode="light"
          language="it"
        />
      ) : (
        <div />
      )}
    </Flex>
  )
}

export default EventDate

import { Share } from '@capacitor/share'
import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdShare } from 'react-icons/io'
import { ReactComponent as WarningIcon } from './../../../assets/warning-icon.svg'

import { selectorAuth } from '@features/auth/auth.slice'
import { useFormbitContext } from '@radicalbit/formbit'
import useGetEventById from '@src/screens/chat/hooks/use-get-event-by-id'
import { Attendance } from '@src/typings/database'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import AttendanceDeletionDialog from '../dialog/attendance-deletion-dialog'
import EventDeletionDialog from '../dialog/event-deletion-dialog'
import ShareEventDialog from '../dialog/share-event-dialog'
import { EventScreenSchema } from '../event-screen-schema'

const EventScreenHeader = props => {
  const { write } = useFormbitContext<EventScreenSchema>()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const title = data?.title
  const text = data?.description
  const categoryName = data?.category?.name

  const shareEvent = async () => {
    const { value: canShare } = await Share.canShare()
    if (!canShare) {
      write('__metadata.isSocialOpen', true)
      return
    }

    try {
      await Share.share({
        dialogTitle: 'Condividi evento sui social',
        title,
        text,
        url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Flex direction="row" justify="center" align="center" pt="2" pb="2" position="relative" width="100%" height="fit-content">
        <Icon as={IoMdShare} boxSize="1.6em" onClick={shareEvent} _hover={{ color: 'brand.500' }} />

        <Spacer />

        <Text fontSize="15px" fontWeight="600" fontFamily="Poppins">
          #{categoryName}
        </Text>

        <Spacer />

        <ActionMenu />
      </Flex>

      <ShareEventDialog />

      <EventDeletionDialog />

      <AttendanceDeletionDialog />
    </>
  )
}

const ActionMenu = () => {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  if (isOrganizer) {
    return <EventOrganizerHeaderActionMenu />
  }

  return <EventAttendanceHeaderActionMenu />
}

const EventOrganizerHeaderActionMenu = () => {
  const navigate = useNavigate()
  const { write } = useFormbitContext<EventScreenSchema>()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const startDate = data?.startDate
  const isEventStartAfter = moment(startDate).isAfter()

  const { user } = useSelector(selectorAuth)
  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const openEditingEventScreen = () => navigate('edit')

  const deleteEvent = async () => {
    if (isOrganizer) {
      write('__metadata.isDeleteOpen', true)
    }
  }

  const goToAssistance = () => {
    window.open('https://sbam.social/supporto', '_blank')
  }

  if (isEventStartAfter) {
    return (
      <Box pos="relative" top="1">
        <Menu>
          <MenuButton>
            <Icon as={GiHamburgerMenu} boxSize="1.6em" _hover={{ color: 'brand.500' }} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={openEditingEventScreen}>Modifica evento</MenuItem>
            <MenuItem onClick={deleteEvent}>Cancella evento</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    )
  }
  return <Icon as={WarningIcon} boxSize="1.6em" _hover={{ color: 'brand.500' }} cursor="pointer" onClick={goToAssistance} />
}

const EventAttendanceHeaderActionMenu = () => {
  const navigate = useNavigate()
  const { write } = useFormbitContext<EventScreenSchema>()

  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const isApproved = !!attendance?.approvedAt

  const goToAssistance = () => {
    window.open('https://sbam.social/supporto', '_blank')
  }

  const deleteAttendance = async () => {
    write('__metadata.isDeleteAttendanceOpen', true)
  }

  const goToReportProfile = () => {
    navigate('/profile/65508a651530cb00136f5548')
  }

  if (!!attendance) {
    return (
      <Box pos="relative" top="1">
        <Menu>
          <MenuButton>
            <Icon as={GiHamburgerMenu} boxSize="1.6em" _hover={{ color: 'brand.500' }} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={goToReportProfile}>Segnala evento</MenuItem>
            {!isApproved && <MenuItem onClick={deleteAttendance}>Annulla partecipazione</MenuItem>}
          </MenuList>
        </Menu>
      </Box>
    )
  }

  return <Icon as={WarningIcon} boxSize="1.6em" _hover={{ color: 'brand.500' }} cursor="pointer" onClick={goToAssistance} />
}

export default EventScreenHeader

import { useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import useCreateAttendanceMutation from '@hooks/use-create-attendance-mutation'
import { useFormbitContext } from '@radicalbit/formbit'
import { genderMap } from '@src/costants/genders'
import { queryClient } from '@src/query-client'
import useGetEventById from '@src/screens/chat/hooks/use-get-event-by-id'
import { Alert, Confirm } from 'components/shared/confirm/confirm'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

const useCreateAttendance = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { user } = useSelector(selectorAuth)

  const { write } = useFormbitContext()

  const { id } = useParams()
  const { data, isLoading, error } = useGetEventById({ eventId: id! })

  const chatSid = data?.chatSid

  const genderBalanceActive = data?.limitations?.genderBalanceActive
  const genderPartecipants = data?.limitations ? data?.limitations[user.gender + 'Participants'] : 0
  const genderBalanceCounts = data?.genderBalanceCounts ? data?.genderBalanceCounts[user.gender] : 0

  const {
    mutateAsync,
    isLoading: isCreateAttendanceLoading,
    isError,
  } = useCreateAttendanceMutation({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(`getEvent${id}`)
        navigate(`/event/${chatSid}/chat`)
      },
      onError: e => {
        const title = (e?.response?.data as any).mesage || 'Error'
        toast({
          title,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        return
      },
    },
  })

  const isDisabled = isLoading || error || isCreateAttendanceLoading || isError

  const handleCreateAttendance = async () => {
    if (isDisabled) {
      return
    }

    if (!user) {
      await Alert({ title: 'Non registrato', message: `Effettua l'accesso o registrati per partecipare all'evento`, confirmText: 'Continua' })
      navigate('/login')
      return
    }

    if (!user?.avatar) {
      write('__metadata.isCompleteProfileOpen', true)
      return
    }

    if (genderBalanceActive && genderPartecipants - genderBalanceCounts <= 0) {
      const gender = genderMap.find(g => g[0] === user.gender) || ['', 'Sesso non specificato']
      await Alert({ title: 'Posti finiti', message: `I posti per ${gender[1].toLowerCase()} sono finiti`, confirmText: 'ok' })
      return
    }

    if (
      !(await Confirm({
        title: 'Conferma',
        message: `Conferma la tua partecipazione. In caso non potrai più partecipare, annullala per evitare ammonizioni.`,
      }))
    ) {
      return
    }

    await mutateAsync({ eventId: id! })
  }

  return { handleCreateAttendance, isDisabled }
}

export default useCreateAttendance

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { NewProfileSchema } from '@src/screens/profile/schema'
import React from 'react'

export const BanInfoModal = () => {
  const { form, write } = useFormbitContext<NewProfileSchema>()
  const isBanInfoOpen = form?.__metadata?.isBanInfoOpen ?? false

  const handleOnClose = () => {
    write('__metadata.isBanInfoOpen', false)
  }

  return (
    <Modal isCentered closeOnOverlayClick isOpen={isBanInfoOpen} onClose={handleOnClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="15px" pb="20px">
        <ModalHeader>Utente bannato!</ModalHeader>
        <ModalCloseButton borderRadius={'15px'} />
        <ModalBody>
          <Text>
            Questo utente è stato bannato per comportamenti scorretti ripetuti come non essersi presentato a un evento a cui partecipava o aver
            infranto il regolamento.
            <br />
            <br />
            Essere bannati impedisce di partecipare e organizzare e limita le funzioni dell’app.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import axios, { AxiosResponse } from 'axios'
import { getFirebaseAuth } from 'services/firebase.service'
import { Options, UsersBulk } from './api.service.d'
import { EventDoneDTO, EventDTO, UserEdit, UserProfileDTO } from '@src/typings/database'


axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.baseURL = process.env.REACT_APP_API_URL

// const parseQueryParams = (object: Map<string, unknown>) => {
//   return Object.entries(object)
//     .reduce((acc: string[], [key, value]) => {
//       // NOTE: this removes undefined, null and object values
//       if (value === undefined || value === null) {
//         return acc
//       } else if (typeof value === 'object') {
//         if (Array.isArray(value) && value.length) {
//           return [...acc, value.map(element => `${key}[]=${element}`).join('&')]
//         } else {
//           return acc
//         }
//       } else {
//         return [...acc, `${key}=${value}`]
//       }
//     }, [])
//     .join('&')
// }

class Api {
  static request = async <T>(
    method: any,
    url: string,
    params: any = {},
    headers: any = {},
    data: any = {},
    useToken: boolean,
  ): Promise<AxiosResponse<T>> => {
    if (useToken) {
      const auth = getFirebaseAuth()
      const token = await auth.currentUser?.getIdToken(false)
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }
    return axios({ method, url, params, headers, data })
  }

  static postImage = async <T>(url: string, data: any = {}, useToken = false): Promise<AxiosResponse<T>> => {
    const headers: any = { 'Content-Type': 'multipart/form-data; boundary=DataBoundary' }

    if (useToken) {
      const auth = getFirebaseAuth()
      const token = await auth.currentUser?.getIdToken(false)
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }
    return axios({
      url,
      data,
      method: 'POST',
      headers,
    })
  }
  static putImage = async <T>(url: string, data: any = {}, useToken = false): Promise<AxiosResponse<T>> => {
    const headers: any = { 'Content-Type': 'multipart/form-data; boundary=DataBoundary' }

    if (useToken) {
      const auth = getFirebaseAuth()
      const token = await auth.currentUser?.getIdToken(false)
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }
    return axios({
      url,
      data,
      method: 'PUT',
      headers,
    })
  }

  static get = async <T = any>(url: string, opt: Options, useToken = false) => Api.request<T>('get', url, opt.params, opt.headers, opt.data, useToken)
  static post = async <T = any>(url: string, opt: Options, useToken = false) =>
    Api.request<T>('post', url, opt.params, opt.headers, opt.data, useToken)
  static delete = async <T = any>(url: string, opt: Options, useToken = false) =>
    Api.request<T>('delete', url, opt.params, opt.headers, opt.data, useToken)
  static patch = async <T = any>(url: string, opt: Options, useToken = false) =>
    Api.request<T>('patch', url, opt.params, opt.headers, opt.data, useToken)
  static put = async <T = any>(url: string, opt: Options, useToken = false) => Api.request<T>('put', url, opt.params, opt.headers, opt.data, useToken)

  // App requests

  static currentProfile = async () => Api.get('/user/current', {}, true)
  static currentCommunities = async () => Api.get('/user/current/communities', {}, true)
  static currentFollowings = async () => Api.get('/user/current/followings', {}, true)
  static sendFollowingRequest = async (data = {}) => Api.post('/followingRequests', data, true)
  static acceptFollowingRequest = async (requestId: string) => Api.put(`/followingRequests/${requestId}/accept`, {}, true)
  static declineFollowingRequest = async (requestId: string) => Api.put(`/followingRequests/${requestId}/decline`, {}, true)
  static cancelFollowingRequest = async (requestId: string) => Api.delete(`/followingRequests/${requestId}`, {}, true)
  static deleteFollowing = async (followingId: string) => Api.delete(`/following/${followingId}`, {}, true)
  static getUserFollowings = async (userId: string) => Api.get(`/user/${userId}/followings`, {}, true)
  static deleteProfile = async () => Api.delete('/user/current', {}, true)
  static editLastPosition = async (data = {}) => Api.put('/user/current/lastPosition', data, true)
  static editAvatar = async (data = {}) => Api.putImage('/user/current/avatar', data, true)
  static notifications = async () => Api.get('/user/current/notifications', {}, true)
  static readNotification = async (id: string) => Api.put('/user/current/notifications/' + id + '/read', {}, true)
  static resolveWarning = async (warningId: string) => Api.post(`/user/current/warnings/${warningId}/resolve`, {}, true)
  static profile = async (id: string) => Api.get<UserProfileDTO>('/user/' + id, {}, true)
  static event = async (id: string) => await Api.get<EventDTO>('/events/' + id, {}, true)
  static places = async (inputText: string) => Api.get('/places/', { params: { inputText } }, true)
  static place = async (placeId: string | number) => Api.get('/places/' + placeId, {}, true)
  static placesFromCoordinates = async (lat: string | number, lon: string | number) =>
    Api.get('/places/fromCoordinates', { params: { lat, lon } }, true)
  static approveAttendance = async (id: string, attendanceId: string, attendanceCode: string) =>
    Api.put(
      '/events/' + id + '/attendances/approve',
      {
        data: {
          attendanceId,
          attendanceCode,
        },
      },
      true,
    )
  static complainUserInEvent = async (eventId: string, data = {}) => Api.post(`/events/${eventId}/complains`, { data }, true)
  static attendancedEvents = async (id: string, params: {}) => Api.get('/user/' + id + '/attendancedEvents', { params }, true)
  static organizedEvents = async (id: string, params: {}) => Api.get('/user/' + id + '/organizedEvents', { params }, true)
  static searchEvents = async (params = {}) => Api.get('/events/search', { params }, true)
  static categories = async () => Api.get('/categories', {}, true)
  static quoteEvent = async (participantsCount: number) => Api.get<{ organizeCost: number }>(`/events/organizeCosts/${participantsCount}`, {}, true)
  static createEvent = async (data = {}) => Api.postImage<string>('/events', data, true)
  static editEvent = async (id: string, data = {}) => Api.putImage('/events/' + id, data, true)
  static createAttendance = async (eventId: string) => Api.post('/attendances/', { data: { eventId } }, true)
  static deleteAttendance = async (eventId: string, attendanceId: string, attendanceCode: string) =>
    Api.put('/events/' + eventId + '/attendances/delete', { data: { attendanceId, attendanceCode } }, true)
  static deleteEvent = async (eventId: string) => Api.delete('/events/' + eventId, {}, true)

  static facebookLogin = async (token: string) => Api.post('/user/login/facebook', { data: { token } })
  static login = async (email: string, password: string) => Api.post('/user/login/email', { data: { email, password } })
  static signUp = async (data: object) => Api.post('/user/registration', { data })
  static signUpV2 = async (data: object) => Api.post('/user/registrationV2', { data })
  static sendPhoneCode = async (data: object) => Api.post('/user/register/user/phone/send-code', { data }, true)
  static verifyPhoneCode = async (data: object) => Api.post('/user/register/user/phone/verify-code', { data }, true)

  static forgotPassword = async (email: string) =>
    Api.post('/user/password/requestReset', {
      data: { email },
    })
  static resetPassword = async (password: string, token: string, userId: string) =>
    Api.post('/user/password/reset', {
      data: { password, userId, token },
    })
  static confirmSignup = async (token: string, userId: string) =>
    Api.post('/user/email/confirmRegistration', {
      data: { token, userId },
    })

  static editUser = async (data: Partial<UserEdit>) => Api.put('/user/current', { data }, true)
  static setNotificationToken = async (token: string) => Api.post('/user/current/notificationTokens', { data: { token } }, true)
  static getChatToken = async () => Api.get('/chat/token', {}, true)
  static currentUserDoneEvents = async () => Api.get<EventDoneDTO>('/user/current/doneEvents', {}, true)
  static userDoneEvents = async (userId: string) => Api.get<EventDoneDTO>(`/user/${userId}/doneEvents`, {}, true)

  static on401 = (callback: any) => {
    return axios.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        if (response && response.status === 401) callback(response)
        return Promise.reject(error)
      },
    )
  }

  static getUsersBulk = async (data: UsersBulk) => Api.post('/user/queryUsersBulk', { data }, true)

  static addChartRoomOneToOne = async (userId: string) => Api.post<{ chatSid: string }>('/chat/userChat', { data: { userId } }, true)
}


export default Api

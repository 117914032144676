import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { FooterSchema } from '@src/components/layout/footer/schema'
import { EventScreenSchema } from '@src/screens/event/event-screen-schema'
import { EventsSchema } from '@src/screens/events/events-schema'
import { NewProfileSchema } from '@src/screens/profile/schema'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CompleteProfileModal = () => {
  const navigate = useNavigate()

  const { form, write } = useFormbitContext<EventScreenSchema | FooterSchema | NewProfileSchema | EventsSchema>()
  const isCompleteProfileOpen = form?.__metadata?.isCompleteProfileOpen ?? false

  const handleOnClick = () => {
    write('__metadata.isCompleteProfileOpen', false)
    navigate('/settings')
  }

  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isCompleteProfileOpen} onClose={handleOnClick}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalHeader fontWeight="bold">Profilo non completato</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Text>Imposta una foto del profilo per poter organizzare eventi e partecipare ad essi</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleOnClick}>
            Annulla
          </Button>
          <Button onClick={handleOnClick}>Completa profilo</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProfileModal

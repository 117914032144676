import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import React from 'react'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { ReactComponent as BoltsIcon } from './../../assets/bolts.svg'
import { EventScreenSchema } from './event-screen-schema'
import useCreateAttendance from '@hooks/use-create-attendance'

const UnblockChatModal = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()
  const isUnblockChatModalOpen = form?.__metadata?.isUnblockChatModalOpen as boolean

  const { handleCreateAttendance } = useCreateAttendance()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendanceCost = data?.attendanceCost

  const joinParticipants = async () => {
    write('__metadata.isUnblockChatModalOpen', false)
    await handleCreateAttendance()
  }

  const handleOnClose = () => {
    write('__metadata.isUnblockChatModalOpen', false)
  }

  return (
    <Modal isCentered isOpen={isUnblockChatModalOpen} onClose={handleOnClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent mr="10px" ml="10px">
        <ModalHeader fontWeight="bold">Sblocca la chat!</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>La chat di gruppo dell'evento è riservata ai soli partecipanti. Partecipa all'evento per accedere.</ModalBody>
        <ModalFooter>
          <Button
            fontWeight="600"
            fontFamily="Poppins"
            textAlign="center"
            variant="solid"
            height="40px"
            width="100%"
            rightIcon={<BoltsIcon />}
            fontSize="17px"
            onClick={joinParticipants}>
            Unisciti ai partecipanti -{attendanceCost}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UnblockChatModal

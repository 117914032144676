import Api from "@src/services/api/api.service";
import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";

const useCreateAttendanceMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
    return useMutation(
      'create-attendance',
      async ({ eventId }: { eventId: string}) => {
        await Api.createAttendance(eventId)
      },
      {
        ...options,
      },
    )
  }

  export default useCreateAttendanceMutation
import { PAGE_ENUM } from '@src/costants'
import * as yup from 'yup'

const newProfileSchema = yup.object({
  __metadata: yup.object({
    isCompleteProfileOpen: yup.boolean(),
    isProfileMenuOpen: yup.boolean(),
    isDisclosureOpen: yup.boolean(),
    isAdmonitionInfoOpen: yup.boolean(),
    isBanInfoOpen: yup.boolean(),
    page: yup.string(),
  }),
})

const initialValues = {
  __metadata: {
    isCompleteProfileOpen: false,
    isProfileMenuOpen: false,
    isDisclosureOpen: false,
    isAdmonitionInfoOpen: false,
    isBanInfoOpen: false,
    page: PAGE_ENUM.PAST,
  },
}

export type NewProfileSchema = yup.InferType<typeof newProfileSchema>
export { newProfileSchema, initialValues }

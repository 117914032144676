import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useCancelFollowingRequestMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'cancel-following-request',
    async ({ followingSentRequestId }: { followingSentRequestId: string }) => {
      await Api.cancelFollowingRequest(followingSentRequestId)
    },
    { ...options },
  )
}

export default useCancelFollowingRequestMutation

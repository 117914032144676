import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineEuro } from 'react-icons/md'
import { useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { useFormbitContext } from '@radicalbit/formbit'
import { EventScreenSchema } from './event-screen-schema'

const EventCost = () => {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const cost = data?.cost ?? 0

  const { write } = useFormbitContext<EventScreenSchema>()

  const handleOnClick = () => {
    write('__metadata.isEventCostModalOpen', true)
  }

  return (
    <>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Flex direction="row" alignItems="center">
          <Icon mr="2" as={MdOutlineEuro} boxSize="20px" />
          <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" lineHeight="22.5px" mr="2">
            {cost.toFixed(2)} - pagamento fuori app
          </Text>
        </Flex>

        <Button
          onClick={handleOnClick}
          variant="outline"
          height="30px"
          fontFamily="Poppins"
          fontWeight="500"
          fontSize="15px"
          lineHeight="18px"
          textAlign="center"
          width="62px"
          pl="9"
          pr="9">
          Avviso
        </Button>
      </Flex>
    </>
  )
}

export default EventCost

import CheckLocationPermission from '@shared/check-location-permission'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectorAuth } from 'features/auth/auth.slice'

const AuthGate = ({ children }: { children: ReactNode }) => {
  const { firebaseUser, isAuthenticated } = useSelector(selectorAuth)

  if (!isAuthenticated) {
    return <Navigate to={'/access'} />
  }

  if (!firebaseUser?.emailVerified) {
    return <Navigate to={'/email-not-verified'} />
  }

  return <CheckLocationPermission>{children}</CheckLocationPermission>
}

export default AuthGate

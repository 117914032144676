import * as yup from 'yup'

const footerSchema = yup.object({
  __metadata: yup.object({
    isCompleteProfileOpen: yup.boolean(),
  }),
})

export type FooterSchema = yup.InferType<typeof footerSchema>
export default footerSchema

import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'

const EventParticipants = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const attendances = data?.attendances

  const goToProfile = (userId: string | undefined) => {
    if (userId !== undefined) {
      navigate(`/profile/${userId}`)
    }
    return
  }

  if (!attendances) {
    return <></>
  }

  return (
    <EventParticipantContainer>
      {attendances.map(e => {
        return (
          <VStack spacing="4px" onClick={() => goToProfile(e.user?._id)}>
            <UserAvatar size="lg" borderWidth="3px" borderColor="#D9D9D9" src={e.user?.avatar} key={e.user?._id} />

            <Text fontFamily="Poppins" fontWeight="300" fontSize="12px" lineHeight="18px" textAlign="center" textColor="#787B80">
              {e.user?.firstName}
            </Text>
          </VStack>
        )
      })}
    </EventParticipantContainer>
  )
}

const EventParticipantContainer = ({ children }) => (
  <Box height="fit-content" width="100%">
    <HStack spacing="10px" overflowX="auto" height="fit-content" width="100%">
      {children}
    </HStack>
  </Box>
)

export default EventParticipants

import { AspectRatio, Button, Flex, Spacer, Text } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { useFormbitContext } from '@radicalbit/formbit'
import { Attendance } from '@src/typings/database'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { EventScreenSchema } from './event-screen-schema'

const EventCover = () => {
  const navigate = useNavigate()

  const { write } = useFormbitContext<EventScreenSchema>()

  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const title = data?.title
  const coverUrl = data?.coverUrl
  const chatSid = data?.chatSid

  const startDate = data?.startDate
  const isAfterEvent = moment(startDate).isAfter()

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const goToChat = () => {
    if (!chatSid) {
      return
    }

    if (!!attendance || isOrganizer) {
      navigate(`/event/${chatSid}/chat`)
      return
    }

    if (!attendance && !isOrganizer && isAfterEvent) {
      write('__metadata.isUnblockChatModalOpen', true)
      return
    }
  }

  return (
    <>
      <AspectRatio ratio={375 / 240} bg={`url('${coverUrl}') center`} bgSize="cover" ml="-5" mr="-5" height="200px" mb="5">
        <Flex direction="column" bgColor="rgba(1, 25, 54, 0.7)" onClick={goToChat} pr="5" pl="5">
          <Spacer />

          <Text fontSize="20px" fontWeight="600" fontFamily="Poppins" textColor="white" lineHeight="30px">
            {title}
          </Text>

          <Spacer />
          <Spacer />

          {chatSid && (attendance || isAfterEvent) && (
            <Button
              variant="outline_white_text"
              width="165px"
              height="40px"
              borderRadius="15px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22.5px"
              onClick={goToChat}>
              Chat evento
            </Button>
          )}
          <Spacer />
        </Flex>
      </AspectRatio>
    </>
  )
}

export default EventCover

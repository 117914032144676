import Logo from '@assets/sbam-logo-orange.svg'
import { Box, Flex, Spacer } from '@chakra-ui/react'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { REMOTE_CONFIG_PARAMS, firebaseApp } from '@services/firebase.service'
import { CreateEventIcon, SearchIcon } from 'components/shared/icons/icons'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import { selectorAuth } from 'features/auth/auth.slice'
import { getBoolean, getRemoteConfig } from 'firebase/remote-config'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'
import footerSchema, { FooterSchema } from './schema'
import CompleteProfileModal from '@src/components/shared/complete-profile-modal/complete-profile-modal'

const initialValues = {
  __metadata: {
    isCompleteProfileOpen: false,
  },
}

const Footer = () => {
  return (
    <FormbitContextProvider initialValues={initialValues} schema={footerSchema}>
      <FooterInner />
    </FormbitContextProvider>
  )
}

const FooterInner = () => {
  const { user } = useSelector(selectorAuth)

  if (!user) {
    return null
  }

  return (
    <>
      <Box height="60px" width="100%"></Box>

      <Box position="fixed" bottom="0" left="0" width="100%" height="env(safe-area-inset-bottom)" backgroundColor="#ffffff" zIndex="100"></Box>

      <Flex
        alignItems="center"
        height="65px"
        px="30px"
        position="fixed"
        bottom="0"
        left="50%"
        transform="translate(-50%)"
        width="100%"
        maxWidth="500px"
        mx="auto"
        borderTop="1px solid #F7F7F7"
        backgroundColor="#FFFFFF"
        zIndex={100}
        marginBottom="env(safe-area-inset-bottom)">
        <Flex m="auto" alignItems="center" maxWidth="500px" css={{ gap: '20px' }} width="100%">
          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <Search />
          </Flex>

          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <CreateEvent />
          </Flex>

          <Spacer />

          <Flex direction="column" justify="center" align="center">
            <Profile />
          </Flex>

          <Spacer />
        </Flex>
      </Flex>

      <CompleteProfileModal />
    </>
  )
}

function Search() {
  const { pathname } = useLocation()

  return (
    <Link to="/">
      <SearchIcon boxSize={7} color={pathname === '/' ? '#FF7436' : '#978F90'} />
    </Link>
  )
}

function CreateEvent() {
  const navigate = useNavigate()

  const { user } = useSelector(selectorAuth)
  const { pathname } = useLocation()

  const { write } = useFormbitContext<FooterSchema>()

  const remoteConfig = getRemoteConfig(firebaseApp)
  const createEventChatbotFlag = getBoolean(remoteConfig, REMOTE_CONFIG_PARAMS.CREATE_EVENT_CHATBOT)

  const isBeta = process.env.REACT_APP_IS_BETA === 'true'

  const handleOnClick = async () => {
    if (!user?.avatar) {
      write('__metadata.isCompleteProfileOpen', true)
      return
    }
    const createEventPath = createEventChatbotFlag ? '/event/create/chat-bot' : '/event/create/wizard'
    navigate(createEventPath)
    return
  }

  return (
    <Box boxSize={isBeta ? 12 : 10} cursor="pointer" onClick={handleOnClick}>
      {isBeta ? (
        <img src={Logo} alt="sbam-logo-orange" />
      ) : (
        <CreateEventIcon boxSize={10} color={pathname === '/event/create' ? '#FF7436' : '#978F90'} cursor="pointer" />
      )}
    </Box>
  )
}

function Profile() {
  const { user } = useSelector(selectorAuth)

  const { pathname } = useLocation()
  const isProfile = matchPath({ path: '/profile' }, pathname)

  return (
    <Link to="/profile">
      <UserAvatar src={user?.avatar} boxSize={7} borderColor={isProfile ? '#FF7436' : '#978F90'} />
    </Link>
  )
}

export default Footer

import { selectorAuth } from '@features/auth/auth.slice'
import { useFormbitContext } from '@radicalbit/formbit'
import { queryClient } from '@src/query-client'
import useGetEventById from '@src/screens/chat/hooks/use-get-event-by-id'
import Api from '@src/services/api/api.service'
import { Attendance } from '@src/typings/database'
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { EventScreenSchema } from '../../event-screen-schema'

const useHandleConfirmDeleteAttendance = () => {
  const { user } = useSelector(selectorAuth)
  const { write } = useFormbitContext<EventScreenSchema>()

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const attendanceId = attendance?._id
  const attendanceCode = attendance?.code

  const { mutateAsync, isLoading } = useDeleteAttendanceMutation({})

  const handleConfirmDeleteAttendance = async () => {
    if (!attendance || isLoading) {
      return
    }

    await mutateAsync({ eventId: id!, attendanceId: attendanceId!, attendanceCode: attendanceCode! })

    queryClient.invalidateQueries(`getEvent${id}`)
    queryClient.invalidateQueries(`conversations`)
    write('__metadata.isDeleteAttendanceOpen', false)
  }

  return { handleConfirmDeleteAttendance, isLoading }
}

const useDeleteAttendanceMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'delete-attendance',
    async ({ eventId, attendanceId, attendanceCode }: { eventId: string; attendanceId: string; attendanceCode: string }) => {
      await Api.deleteAttendance(eventId!, attendanceId, attendanceCode)
    },
    { ...options },
  )
}

export default useHandleConfirmDeleteAttendance

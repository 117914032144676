import React from 'react'
import { Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { EventScreenSchema } from '@src/screens/event/event-screen-schema'
import QRReader from '../qr-reader/qr-reader'
import { ReactComponent as BoltsIcon } from './../../../assets/bolts.svg'
import useHandleScanQrCode from './use-handle-scan-qr-code'

const ScanQRCodeComponent = () => {
  const { form, write } = useFormbitContext<EventScreenSchema>()
  const isQROpen = form?.__metadata?.isQROpen as boolean

  const { handleScanQrCode, isLoading } = useHandleScanQrCode()

  const handleQR = async (payload: string) => {
    await handleScanQrCode(payload)
    write('__metadata.isQROpen', false)
  }

  const handleOnClose = () => {
    write('__metadata.isQROpen', false)
  }

  const handleOnClick = () => {
    write('__metadata.isQROpen', true)
  }

  return (
    <>
      <Button variant="solid" rightIcon={<BoltsIcon />} onClick={handleOnClick}>
        <Text fontSize="18px" fontWeight="600" fontFamily="Poppins">
          Conferma partecipanti +1
        </Text>
      </Button>

      <Modal isCentered isOpen={isQROpen} onClose={handleOnClose}>
        <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
        <ModalContent mr="10px" ml="10px">
          <ModalHeader fontWeight="bold">Scansiona QR partecipanti</ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody pb="40px">
            <Flex direction="column" gap=".5rem">
              <Center>
                {!isLoading && <QRReader onQrFound={handleQR} />}
                {isLoading && <Text>Invio partecipazione</Text>}
              </Center>

              <Text textAlign="left" fontSize="15px" mb="2">
                Conferma le presenze dei partecipanti e ottieni saette bonus.
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ScanQRCodeComponent

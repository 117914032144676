import * as yup from 'yup'

const eventsSchema = yup.object({
  __metadata: yup.object({
    isCompleteProfileOpen: yup.boolean(),
  }),
})

export type EventsSchema = yup.InferType<typeof eventsSchema>
export default eventsSchema

import { Heading, Text, Button, Link, Flex, Image } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { User, reload, sendEmailVerification } from 'firebase/auth'
import moment from 'moment'
import image from 'assets/signup-2.svg'

import React from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { Alert } from '@src/components/shared/confirm/confirm'
import { useNavigate } from 'react-router'
import { sendEmailVerificationOptions } from '@src/services/firebase.service'

const dateOfSwitch = moment('2024-12-01')

const EmailNotVerified = () => {
  const { firebaseUser } = useSelector(selectorAuth)
  const navigate = useNavigate()

  const { mutateAsync, isLoading } = useMutation('send-verification-email', async (firebaseUser: User) => {
    try {
      await sendEmailVerification(firebaseUser, sendEmailVerificationOptions)
      return true
    } catch {
      return false
    }
  })

  const dateOfRegistration = moment(firebaseUser?.metadata.creationTime)
  const isNewUser = dateOfRegistration.isAfter(dateOfSwitch)

  const title = isNewUser
    ? 'Ci sei quasi, ti abbiamo inviato una mail per verificare il tuo account.'
    : 'Stiamo rendendo la nostra community più sicura evitando account fake, per questo ti abbiamo inviato una mail per verificare il tuo account.'
  const description = isNewUser
    ? 'Clicca sul link che trovi nella mail e inizia a fare nuove amicizie!'
    : 'Ti basta cliccare sul link che trovi nella mail che ti abbiamo inviato e potrai continua a fare nuove amicizie qui su Sbam!'

  const resendEmail = async () => {
    const isSuccess = await mutateAsync(firebaseUser!)

    if (isSuccess) {
      Alert({ title: 'Email inviata', message: `Controlla nella tua casella email.` })
      return
    }

    Alert({ title: 'Tentativo invio email fallito', message: `Ricarica la pagina e riprova.` })
  }

  const refresh = async () => {
    await reload(firebaseUser!)
    navigate('/')
  }

  return (
    <Wrapper height="100%" pb="90px" pl="10px" pr="10px" pt="20px">
      <Flex direction="column" justify="space-between" h="100%">
        <Image src={image} height="200px" />
        <Flex direction="column" gap={4}>
          <Heading as="h1" size="lg" textAlign="center">
            Email non verificata
          </Heading>
          <Text fontSize={14} textAlign="center">
            {title}
            <br />
            <br />
            {description}
          </Text>
        </Flex>

        <Flex direction="column" gap={4}>
          <Button width="100%" variant="solid" onClick={resendEmail} isLoading={isLoading}>
            Re-invia email di conferma
          </Button>

          <Button width="100%" variant="outline" onClick={refresh} size="sm">
            Ricarica
          </Button>

          <Link href="https://sbam.social/supporto" isExternal>
            <Button width="100%" variant="link" size="sm">
              Vai al supporto
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default EmailNotVerified

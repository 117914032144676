import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useDeleteFollowingMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'delete-following',
    async ({ followingId }: { followingId: string }) => {
      await Api.deleteFollowing(followingId)
    },
    { ...options },
  )
}

export default useDeleteFollowingMutation

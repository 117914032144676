import Api from '@src/services/api/api.service'
import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

const useAcceptFollowingRequestMutation = ({ options }: { options?: UseMutationOptions<unknown, AxiosError, unknown, unknown> }) => {
  return useMutation(
    'accept-following-request',
    async ({ followingSentRequestReceivedId }: { followingSentRequestReceivedId: string }) => {
      await Api.acceptFollowingRequest(followingSentRequestReceivedId)
    },
    { ...options },
  )
}

export default useAcceptFollowingRequestMutation

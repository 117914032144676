import Api from '@src/services/api/api.service'
import { UserProfileDTO } from '@src/typings/database'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

interface IUseGetProfileById {
  profileId: string
  options?: UseQueryOptions<unknown, AxiosError, UserProfileDTO>
}

const useGetProfileById = ({ profileId, options }: IUseGetProfileById) => {
  return useQuery({
    queryKey: `profile${profileId}`,
    queryFn: async () => {
      const response = await Api.profile(profileId)
      return response.data
    },
    enabled: !!profileId,
    ...options,
  })
}

export default useGetProfileById

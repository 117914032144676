import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import CompleteProfileModal from '@components/shared/complete-profile-modal/complete-profile-modal'
import { AdmonitionInfoModal } from '@components/shared/warning-info-modals/admonition-info-modal'
import { BanInfoModal } from '@components/shared/warning-info-modals/ban-info-modal'
import Wrapper from '@components/shared/wrapper/wrapper.component'
import useAmIAdminOrSupport from '@hooks/use-am-i-admin-or-support'
import useIsMe from '@hooks/use-is-me'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { AdvBanner } from '@shared/adv-banner'
import { PAGE_ENUM } from '@src/costants'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useGetDoneEventsByUser from './hooks/queries/use-get-done-events-by-user'
import useGetFollowers from './hooks/queries/use-get-followers'
import useGetProfileById from './hooks/queries/use-get-profile-by-id'
import ProfileMenuModal from './profile-menu-modal'
import { initialValues, newProfileSchema, NewProfileSchema } from './schema'
import {
  AcceptDeclineFollowingRequestContainer,
  AddFriendsButton,
  CancelFollowingRequestLightButton,
  DeleteCancelSendFollowingRequestContainer,
  DoNotFollowingMoreButton,
  EventInstructions,
  EventsParticipatesAndOrganizesButton,
  EventsPreview,
  FollowerInstructions,
  Header,
  HeaderUserDetail,
  MoreFollowingActionMenu,
  SendFollowingRequestButton,
  SendMessageToUser,
  useCommonToast
} from './shared-components'

const NewProfileScreen = () => {
  return (
    <FormbitContextProvider initialValues={initialValues} schema={newProfileSchema}>
      <NewProfileScreenInner />
    </FormbitContextProvider>
  )
}

function NewProfileScreenInner() {
  const toast = useToast()
  const scrollToRef = useRef<null | HTMLDivElement>(null)

  const isAdminOrSupport = useAmIAdminOrSupport()

  const { isMe, profileId, userId } = useIsMe()

  const { form, write } = useFormbitContext<NewProfileSchema>()
  const page = form?.__metadata?.page

  const { data: eventsData } = useGetDoneEventsByUser({ profileId, userId })
  const events = page === 'pastEvents' ? eventsData?.past : eventsData?.future

  const { isLoading: eventsIsLoading, error: eventsError } = useGetDoneEventsByUser({ profileId, userId })
  const { isLoading: followersIsLoading, error: followersError } = useGetFollowers({ profileId, userId })

  const { isLoading: profileIsLoading, error: profileError, data: profileData } = useGetProfileById({ profileId })
  const existingUserChat = profileData?.existingUserChat
  const iFollowUserObj = profileData?.following
  const userFollowsMeObj = profileData?.follower
  const iSentRequestObj = profileData?.followingRequestSent
  const userSentRequestObj = profileData?.followingRequestReceived

  const isError = profileError || eventsError || followersError
  const isLoading = eventsIsLoading || profileIsLoading || followersIsLoading

  useEffect(() => {
    if (isError && typeof isError == 'string') {
      toast({
        title: isError,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (isError && typeof isError?.message == 'string') {
      toast({
        title: isError.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [isError, toast])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const showSendCancelFollowingRequestButton = !profileError && !isMe && !iFollowUserObj && !userFollowsMeObj && !userSentRequestObj
  const showAcceptDeclineFollowingRequestButton = !profileError && !isMe && !userFollowsMeObj && userSentRequestObj
  const showDeleteCancelSendFollowingRequest = !profileError && !isMe && !iFollowUserObj && userFollowsMeObj && !userSentRequestObj
  const showMoreFollowingActionMenu = !profileError && !isMe && iFollowUserObj && userFollowsMeObj
  const showDoNotFollowingMoreButton = !profileError && !isMe && iFollowUserObj && !userFollowsMeObj && !iSentRequestObj && !userSentRequestObj
  const showPastFutureEventTabs = isMe || (!isMe && iFollowUserObj)
  const showFollowerInstructions = !isMe && !iFollowUserObj
  const showEventsPreview = !profileError && (isMe || (!isMe && iFollowUserObj))
  const showEventsParticipatesAndOrganizesButton = events && !events.length && isMe

  const handleSetPage = ({ pageEnum }: { pageEnum: PAGE_ENUM }) => {
    write('__metadata.page', pageEnum)
  }

  if (isLoading) {
    return <Text>Caricamento...</Text>
  }

  return (
    <Wrapper pt="20px" pb="90px" overflow-y="auto" overflow-x="hidden" gap="12px">
      <Header />

      <HeaderUserDetail />

      <HeaderTabs scrollToRef={scrollToRef} />

      <AdvBanner />

      {isMe && <AddFriendsButton />}

      {showSendCancelFollowingRequestButton && (
        <>
          {!iSentRequestObj && (
            <>
              <SendFollowingRequestButton />
            </>
          )}

          {iSentRequestObj && (
            <>
              <CancelFollowingRequestLightButton />
            </>
          )}
        </>
      )}

      {showAcceptDeclineFollowingRequestButton && (
        <>
          {!iSentRequestObj && (
            <>
              <AcceptDeclineFollowingRequestContainer />
            </>
          )}
        </>
      )}

      {showDeleteCancelSendFollowingRequest && (
        <>
          <DeleteCancelSendFollowingRequestContainer />
        </>
      )}

      {showMoreFollowingActionMenu && (
        <>
          <MoreFollowingActionMenu />
        </>
      )}

      {showSendCancelFollowingRequestButton && (
        <>
        <SendMessageToUser/>
        
        </>
      )}
   

      {showDoNotFollowingMoreButton && (
        <>
          <DoNotFollowingMoreButton />
        </>
      )}

      {showPastFutureEventTabs && (
        <Flex justify="space-around" textAlign="center" scrollBehavior="smooth" ref={scrollToRef} mt="12px">
          <Text
            fontSize="18px"
            cursor="pointer"
            onClick={() => handleSetPage({ pageEnum: PAGE_ENUM.PAST })}
            opacity={page === PAGE_ENUM.PAST ? 1 : 0.5}
            fontWeight="semibold">
            Passati
          </Text>

          <Text
            fontSize="18px"
            cursor="pointer"
            onClick={() => handleSetPage({ pageEnum: PAGE_ENUM.FUTURE })}
            opacity={page === PAGE_ENUM.FUTURE ? 1 : 0.5}
            fontWeight="semibold">
            Futuri
          </Text>
        </Flex>
      )}

      {showFollowerInstructions && (
        <>
          <FollowerInstructions />
        </>
      )}

      <EventInstructions />

      {showEventsPreview && (
        <>
          <EventsPreview />
        </>
      )}

      {showEventsParticipatesAndOrganizesButton && (
        <>
          <EventsParticipatesAndOrganizesButton />
        </>
      )}

      <CompleteProfileModal />
      <ProfileMenuModal />
      <AdmonitionInfoModal />
      <BanInfoModal />
    </Wrapper>
  )
}

const HeaderTabs = ({ scrollToRef }) => {
  const navigate = useNavigate()
  const { showToast } = useCommonToast()

  const { isMe, profileId, userId } = useIsMe()

  const { data } = useGetFollowers({ profileId, userId })
  const followers = data?.followers ?? []
  const followings = data?.followings ?? []
  const iFollowUserObj = !isMe ? data?.following ?? null : null

  const { data: eventsData } = useGetDoneEventsByUser({ profileId, userId })
  const pastEventCount = eventsData?.past.length ?? 0
  const futureEventCount = eventsData?.future.length ?? 0
  const totalEventCount = pastEventCount + futureEventCount

  const goToFollowers = () => {
    if (iFollowUserObj || isMe) {
      const navigatePath = isMe ? '/profile/followers/follower' : `/profile/${profileId}/followers/follower`
      navigate(navigatePath)
      return
    }
    showToast({ title: "Devi seguire l'utente per vedere i suoi follower" })
  }

  const goToFollowings = () => {
    const navigatePath = isMe ? '/profile/followers/seguiti' : `/profile/${profileId}/followers/seguiti`

    if (iFollowUserObj || isMe) {
      navigate(navigatePath)
      return
    }
    showToast({ title: "Devi seguire l'utente per vedere i suoi utenti seguiti" })
  }

  const goToEvents = () => {
    if (iFollowUserObj || isMe) {
      if (scrollToRef.current) {
        const absoluteTop = scrollToRef.current.getBoundingClientRect().top + window.scrollY
        const headerHeight = 60

        window.scrollTo({ top: absoluteTop - headerHeight - 5, behavior: 'smooth' })
      }
      return
    }
    showToast({ title: "Devi seguire l'utente per vedere i suoi eventi" })
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" pl="30px" pr="30px">
      <Box>
        <Text align="center" cursor="pointer" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} onClick={goToFollowers}>
          {followers.length}
        </Text>

        <Text align="center" textColor="#808C9A">
          Follower
        </Text>
      </Box>
      <Box>
        <Text cursor="pointer" align="center" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} onClick={goToFollowings}>
          {followings.length}
        </Text>
        <Text align="center" textColor="#808C9A">
          Seguiti
        </Text>
      </Box>
      <Box>
        <Text align="center" fontWeight="semibold" fontSize="xl" _hover={{ color: 'brand.500' }} cursor="pointer" onClick={goToEvents}>
          {totalEventCount}
        </Text>
        <Text align="center" textColor="#808C9A">
          Eventi
        </Text>
      </Box>
    </Flex>
  )
}

export default NewProfileScreen

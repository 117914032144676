import { selectorAuth } from "@features/auth/auth.slice"
import { useSelector } from "react-redux"
import { useParams } from "react-router"


const useIsMe = () => {
    const { id } = useParams()
    const { user } = useSelector(selectorAuth)
    const profileId = id || user._id

    return { isMe: profileId === user._id, profileId, userId: user._id }
}

export default useIsMe
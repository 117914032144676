import { Flex, Icon, Spacer, Text } from '@chakra-ui/react'
import Attendees from 'components/shared/attendees/attendees.component'
import React from 'react'
import { Attendance } from 'typings/database'
import { ReactComponent as AgeIcon } from '@assets/age-restricion.svg'
import { ReactComponent as FemaleIcon } from '@assets/female-symbol.svg'
import { ReactComponent as NonBinaryIcon } from '@assets/genderqueer.svg'
import { ReactComponent as GroupIcon } from '@assets/group2-icon.svg'
import { ReactComponent as MaleIcon } from '@assets/male-symbol.svg'
import useGetEventById from '../chat/hooks/use-get-event-by-id'
import { useParams } from 'react-router'
import { selectorAuth } from '@features/auth/auth.slice'
import { useSelector } from 'react-redux'

function EventParticipantsInfo() {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const organizerId = data?.organizer?._id
  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const isOrganizeAttendance = attendances && attendances.find((e: Attendance) => e.user._id === organizerId)

  const organizerAttends = attendances && !isOrganizeAttendance
  const organizerAttendance = organizerAttends && { user: organizer }

  const limitations = data?.limitations
  const maxParticipants = data?.limitations?.maxParticipants ?? 0
  const genderBalanceCounts = data?.genderBalanceCounts
  const endDate = data?.endDate

  return (
    <Flex gap="1rem" alignItems="center">
      <MaxParticipants />

      <AgeRange />

      <GenderBalance />

      <Spacer />

      {(isOrganizer || attendance) && (
        <Attendees
          max={maxParticipants}
          list={attendances}
          organizer={organizer}
          organizerAttendance={organizerAttendance}
          limitations={limitations}
          counts={genderBalanceCounts}
          endDate={endDate}
        />
      )}
    </Flex>
  )
}

function MaxParticipants() {
  const { user } = useSelector(selectorAuth)

  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const maxParticipants = data?.limitations?.maxParticipants
  const attendancesCount = data?.attendancesCount
  const attendances = data?.attendances
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)

  const organizer = data?.organizer
  const isOrganizer = user && organizer?._id === user._id

  return (
    <Flex gap=".25rem" alignItems="center">
      <Icon as={GroupIcon} boxSize="19px" color="#011936" />

      <Text fontFamily="Poppins" fontWeight="400" fontSize="15px">
        {(isOrganizer || attendance) && `${attendancesCount}/`}
        {maxParticipants}
      </Text>
    </Flex>
  )
}

function AgeRange() {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const minAge = data?.limitations?.minAge
  const maxAge = data?.limitations?.maxAge

  if (!minAge || !maxAge) {
    return <></>
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <Icon as={AgeIcon} width="15px" height="15px" />

      <Text fontFamily="Poppins" fontWeight="400" fontSize="15px">
        {`${minAge}-${maxAge}`}
      </Text>
    </Flex>
  )
}

function GenderBalance() {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })

  const genderBalanceActive = data?.limitations?.genderBalanceActive

  if (genderBalanceActive) {
    return (
      <Flex gap=".25rem">
        <MaleParticipants />
        <FemaleParticipants />
        <OtherParticipants />
      </Flex>
    )
  }

  return <></>
}

const OtherParticipants = () => {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const otherParticipants = data?.limitations?.otherParticipants

  if (otherParticipants) {
    return (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={NonBinaryIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {otherParticipants}
        </Text>
      </Flex>
    )
  }
  return <></>
}

const FemaleParticipants = () => {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const femaleParticipants = data?.limitations?.femaleParticipants

  if (femaleParticipants) {
    return (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={FemaleIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {femaleParticipants}
        </Text>
      </Flex>
    )
  }
  return <></>
}

const MaleParticipants = () => {
  const { id } = useParams()
  const { data } = useGetEventById({ eventId: id })
  const maleParticipants = data?.limitations?.maleParticipants

  if (maleParticipants) {
    return (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={MaleIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {maleParticipants}
        </Text>
      </Flex>
    )
  }
  return <></>
}

export default EventParticipantsInfo

import { useToast } from '@chakra-ui/react'
import useApproveAttendanceMutation from '@hooks/use-approve-attendance-mutation'
import { queryClient } from '@src/query-client'
import { useParams } from 'react-router'

const useHandleScanQrCode = () => {
  const toast = useToast()
  const { id: eventId } = useParams()

  const { isLoading, mutateAsync } = useApproveAttendanceMutation({
    options: {
      onSuccess: () => {
        toast({
          title: 'Partecipazione confermata',
          status: 'success',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
        queryClient.invalidateQueries([`getEvent${eventId}`])
      },
      onError: err => {
        alert(`Codice QR non valido: ${(err.response?.data as any).error?.message}`)
        return
      },
    },
  })

  const handleScanQrCode = async (payload: string) => {
    if (isLoading) {
      return
    }

    const { attendanceId, attendanceCode } = JSON.parse(payload)
    await mutateAsync({ eventId: eventId!, attendanceId, attendanceCode })
  }

  return { handleScanQrCode, isLoading }
}

export default useHandleScanQrCode

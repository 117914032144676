import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useFormbitContext } from '@radicalbit/formbit'
import { NewProfileSchema } from '@src/screens/profile/schema'
import React from 'react'

export const AdmonitionInfoModal = () => {
  const { form, write } = useFormbitContext<NewProfileSchema>()
  const isAdmonitionInfoOpen = form?.__metadata?.isAdmonitionInfoOpen ?? false

  const handleOnClose = () => {
    write('__metadata.isAdmonitionInfoOpen', false)
  }

  return (
    <Modal isCentered closeOnOverlayClick isOpen={isAdmonitionInfoOpen} onClose={handleOnClose} size="sm">
      <ModalOverlay />
      <ModalContent borderRadius="15px" pb="20px">
        <ModalHeader>Utente ammonito!</ModalHeader>
        <ModalCloseButton borderRadius={'15px'} />
        <ModalBody>
          <Text>
            Questo utente è stato ammonito per comportamenti scorretti come non essersi presentato a un evento a cui partecipava o aver infranto il
            regolamento.
            <br />
            <br />
            Essere ammoniti aumenta il costo in saette per partecipare e organizzare eventi e limita le funzioni dell’app.
            <br />
            <br />
            Ricevendo 2 ammonizioni si viene bannati per un determinato periodo di tempo.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

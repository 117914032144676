import { queryClient } from '@src/query-client'
import useSendFollowingRequestMutation from './mutations/use-send-following-request-mutation'
import { useToast } from '@chakra-ui/react'
import { selectorAuth } from '@features/auth/auth.slice'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

const useHandleSendFollowingRequest = () => {
  const toast = useToast()

  const { user } = useSelector(selectorAuth)
  const { id } = useParams()
  const userId = id || user._id

  const { isLoading, mutateAsync } = useSendFollowingRequestMutation({
    options: {
      onSuccess: () => {
        toast({
          title: 'Richiesta di following inviata',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        })
        queryClient.invalidateQueries([`followers`])
        queryClient.invalidateQueries([`profile${userId}`])
      },
      onError: err => {
        toast({
          title: 'Error',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        return
      },
    },
  })

  const handleSendFollowingRequest = async () => {
    if (isLoading) {
      return
    }

    await mutateAsync({ userId })
  }

  return { handleSendFollowingRequest, isLoading }
}

export default useHandleSendFollowingRequest
